import { createGlobalStyle, css } from 'styled-components'
import { CheckBoxStyles } from './checkbox'
import { IconStyles } from './icons'
import { RadioStyles } from './radio'

import { Scroll } from './scroll'

import IconArrowButtonLeft from '../assets/icons/icon-arrow-button-left.svg'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
        url('/fonts/montserrat-v15-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  *, *:focus, *:hover {
    outline: none;
  }

  html,
  body,
  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
  }

  a {
    text-decoration: none;
    color: currentColor;

    &:focus {
        outline: none;
    }
  }

  .text-center{
    text-align: center;
  }

  .text-right{
      text-align: right;
  }

  ${({ theme, removeBg }) => css`
    ${Scroll}
    ${RadioStyles(theme)}
    ${IconStyles(theme)}
    ${CheckBoxStyles(theme)}

    .text-color-primary {
      color: ${theme.colors.primary};
    }

    html {
      font-size: 62.5%;
    }

    body {
      font-family: 'Montserrat', sans-serif;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.mediumGrey};

      ${!removeBg &&
      css`
        background-color: #fbfbfb;
      `}

      .text-center {
        text-align: center;
      }

      .link {
        text-decoration: underline;
        font-size: 14px;
        transition: all 400ms;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    .col-group {
      margin-left: -10px;
      margin-right: -10px;
    }

    .col-group:before,
    .col-group:after {
      content: ' ';
      display: table;
    }

    .col-group:after {
      clear: both;
    }

    .IconArrowButtonLeft {
      mask-image: url(${IconArrowButtonLeft});
      mask-size: contain;
      mask-repeat: no-repeat;
      width: 20px;
      height: 10px;
      background-color: ${theme.colors.primary};
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      min-height: 1px;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
    }

    @media (min-width: ${theme.media.ipad}) {
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        float: left;
      }
      .col-12 {
        width: 100%;
      }
      .col-11 {
        width: 91.66666667%;
      }
      .col-10 {
        width: 83.33333333%;
      }
      .col-9 {
        width: 75%;
      }
      .col-8 {
        width: 66.66666667%;
      }
      .col-7 {
        width: 58.33333333%;
      }
      .col-6 {
        width: 50%;
      }
      .col-5 {
        width: 41.66666667%;
      }
      .col-4 {
        width: 33.33333333%;
      }
      .col-3 {
        width: 25%;
      }
      .col-2 {
        width: 16.66666667%;
      }
      .col-1 {
        width: 8.33333333%;
      }
    }
  `}
`

export default GlobalStyles
