import * as auth from './auth/auth'

const defaultOptions = {
  headers: {
    'Authorization': 'Bearer ' + auth.getToken(),
    'Content-Type': 'application/problem+json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  }
}

export default defaultOptions
