import * as S from './styles'

const AccordionButton = ({
  current,
  handleChangeStep,
  trigger,
  handleSubmit
}) => {
  const handleButtonClick = async (forwards) => {
    const success = await handleChangeStep(forwards)
    if (!success) return
  }

  const handleSubmitClick = async () => {
    await handleSubmit()
  }

  return (
    <S.FormFooterContent>
      {current !== 1 && (
        <S.BackButton onClick={() => handleButtonClick(false)}>
          <i className="icon icon-arrow-button-left"></i> Voltar
        </S.BackButton>
      )}

      {current === 3 ? (
        <S.AdvanceButton onClick={handleSubmitClick}>
          {trigger ? <div>Finalizar Cadastro</div> : <div>...Cadastrando</div>}
        </S.AdvanceButton>
      ) : (
        <S.AdvanceButton onClick={() => handleButtonClick(true)}>
          <div>
            Continuar <i className="icon icon-arrow-button-right"></i>
          </div>
        </S.AdvanceButton>
      )}
    </S.FormFooterContent>
  )
}

export default AccordionButton
