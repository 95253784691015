import styled, { css } from 'styled-components'
import { animated } from 'react-spring'


const toastTypeVariations = {
  info: css`
    background: #f1f8ff;
    color: #1877f2;
  `,
  success: css`
    background: #e5f7e7;
    color: #27ae60;
  `,
  error: css`
    background: #feeaea;
    color: #d22630;
  `,
  warning: css`
    background: #fff3e7;
    color: #ff9017;
  `
}
export const Container = styled(animated.div)`
  position: relative;
  padding: 20px 30px 16px 20px;
  border-radius: 5px;
  display: flex;
  & + div {
    margin-top: 8px;
  }
  ${(props) => toastTypeVariations[props.type || 'info']}
  > svg {
    margin: 4px 30px 0 10px;
  }
  div {
    flex: 1;
    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }
  button {
    position: absolute;
    right: 30px;
    top: 24px;
    border: 0;
    background: transparent;
    color: inherit;
    cursor: pointer;
  }
  ${(props) =>
    !props.hasDescription &&
    css`
      align-items: center;
      svg {
        margin-top: 0;
      }
    `}
`
