const cadastro = {
  async cadastroClientePF(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-fisicas`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async preCadastroClientePF(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-fisicasV2`,
      {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async cadastroClientePJ(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-juridicas`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async preCadastroClientePJ(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-juridicasV2`,
      {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async getCEP(CEP) {
    return await fetch(`${process.env.REACT_APP_API_CEPS}${CEP}`)
  }
}

export default cadastro
