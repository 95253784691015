import styled, { css } from 'styled-components'

import {
  Button,
  ButtonBack,
  ButtonIcon,
  ButtonPrimary
} from '../../components/Button/styles'

export const WrapperMedium = styled.div`
  ${({ theme }) => css`
    margin: auto;
    max-width: 1000px;
    position: relative;
    width: 100%;

    @media (max-width: ${theme.media.ipad}) {
      max-width: 90%;
    }
  `}
`

export const FormFooter = styled.div`
  min-height: 100px;
  ${({ theme }) => css`
    @media (max-width: ${theme.media.ipad}) {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: #fbfbfb;
      /* padding: 20px 0; */
      z-index: 999;
    }
  `}
`

export const AdvanceButton = styled.button`
  ${({ theme, disabled }) => css`
    cursor: pointer;
    ${Button(theme)}
    ${ButtonPrimary(theme)}
    ${ButtonIcon}
    margin-left: 15px;
    ${disabled &&
    css`
      background: ${theme.colors.grey};
    `}
  `}
`

export const BackButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    ${Button(theme)}
    ${ButtonBack(theme)}
      ${ButtonIcon}
  `}
`

export const FormFooterContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 30px;

    @media (max-width: ${theme.media.cel}) {
      flex-direction: column-reverse;
      margin-top: 0;

      ${AdvanceButton} {
        display: block;
        margin: 0 0 10px 0 !important;
        width: 100%;
      }
    }
  `}
`
