import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PreRegisterForm from '../../components/PreRegisterForm'
import FormPF from '../../components/FormPF'
import FormPJ from '../../components/FormPJ'
// import { useToast } from '../../hooks/toast'
// import clientes from '../../api/clientes'

// import * as S from './styles'

const RegisterTemplate = () => {
  const dados = useSelector((store) => store.facebook.dados)

  const [type, setType] = useState(undefined)
  const [selectedType, setSelectedType] = useState('pf')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    const { email } = dados
    if (email.length > 0) setEmail(email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {type === undefined && (
        <PreRegisterForm
          type={type}
          setType={setType}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          setSelectedType={setSelectedType}
          selectedType={selectedType}
        />
      )}
      {type && type === 'pf' && (
        <FormPF
          setType={setType}
          setSelectedType={setSelectedType}
          type={type}
          email={email}
          name={name}
        />
      )}
      {type && type === 'pj' && (
        <FormPJ
          setType={setType}
          setSelectedType={setSelectedType}
          type={type}
          email={email}
          name={name}
        />
      )}
    </>
  )
}

export default RegisterTemplate
