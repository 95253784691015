const traceLog = {
  async logAcessos(body) {
    return await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}trace-log/LogAcessos`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async logAcessosPosCadastro(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CHECKOUT}api/Pedido/SalvarDadosRastreamento`,
      {
        method: 'POST',
        headers: {
          // Authorization: `Bearer ${token()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    )
  }
}

export default traceLog
