import * as S from './styles'

const Loader = (props) => {
  return (
    <S.Background>
      <S.LoaderContainer {...props}>
        <S.LoaderDot></S.LoaderDot>
        <S.LoaderDot></S.LoaderDot>
        <S.LoaderDot></S.LoaderDot>
        <S.LoaderDot></S.LoaderDot>
        <S.LoaderDot></S.LoaderDot>
        <S.LoaderDot></S.LoaderDot>
      </S.LoaderContainer>
    </S.Background>
  )
}

export default Loader
