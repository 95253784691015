import { css} from 'styled-components'

export const CheckBoxStyles = (theme) => css`
  [type='checkbox']:not(:checked),
  [type='checkbox']:checked {
    left: -9999px;
    position: absolute;
  }

  [type='checkbox']:not(:checked) + label,
  [type='checkbox']:checked + label {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 23px;
    position: relative;
  }

  [type='checkbox']:not(:checked) + label:before,
  [type='checkbox']:checked + label:before {
    background: transparent;
    border: 1px solid ${theme.colors.primary};
    border-radius: 4px;
    content: '';
    height: 15px;
    left: 0;
    position: absolute;
    top: 2px;
    transition: border-color 0.5s ease;
    vertical-align: middle;
    width: 15px;
  }

  [type='checkbox']:checked + label:before {
    background: ${theme.colors.primary};
  }

  // checked
  [type='checkbox']:not(:checked) + label:after,
  [type='checkbox']:checked + label:after {
    border-color: #fff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: '';
    height: 9px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 10px;
    transform: translate(-50%, -65%) rotate(45deg);
    transition: opacity 0.5s ease, visibility 0.5s ease;
    width: 4px;
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
  }

  // disabled checkbox
  [type='checkbox']:disabled:not(:checked) + label:before,
  [type='checkbox']:disabled:checked + label:before {
    border-color: #cccccc;
    background: #f6f6f6;
  }

  [type='checkbox']:disabled:checked + label:after,
  [type='checkbox']:disabled + label:after {
    border-color: #c1c1c1;
  }
`
