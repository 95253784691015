/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryParam, StringParam } from 'use-query-params'
import Loader from '../../components/Loader'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDados } from '../../store/ducks/facebook'
import { getQueryParamsToTracelog } from '../../utils/traceLogAcessos'
import { useQuery } from '../../hooks/useQuery'

function Auth() {
  const dispatch = useDispatch()

  const router = useHistory()

  const [email] = useQueryParam('email', StringParam)
  const [nome] = useQueryParam('nome', StringParam)
  const [facebookid] = useQueryParam('facebookid', StringParam)

  const [waitGroup, setWaitGroup] = useState(0)

  const query = useQuery()

  useEffect(() => {
    if (waitGroup === 0) {
      dispatch(
        setDados({
          email: email || '',
          nome: nome || '',
          facebookid: facebookid || ''
        })
      )
      getQueryParamsToTracelog(query)
      setWaitGroup(1)
    }
    if (waitGroup === 1) {
      router.push('/cadastro')
    }
  }, [waitGroup])

  return (
    <>
      <Loader />
    </>
  )
}

export default Auth
