import { css } from 'styled-components'

export const PageTitle = (theme) => css`
  font-size: 24px;
  font-weight: 500;
  color: ${theme.colors.primary};
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: ${theme.media.ipad}) {
    font-size: 22px;
    text-align: left;
  }
`
