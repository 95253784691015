import { createContext, useState, ReactNode } from 'react'
import { UserPFProps, UserPJProps } from '../types/user'

type UserContextProps = {
  children: ReactNode
}

type StepProps = {
  pf: number
  pj: number
}

type UserContextType = {
  userType: string
  setUserType: (newState: string) => void
  userPF: UserPFProps
  setUserPF: (newState: UserPFProps) => void
  userPJ: UserPJProps
  setUserPJ: (newState: UserPJProps) => void
  getUserData: (type: string) => void
  createUser: (data: string) => void
  userName: string
  setUserName: (name: string) => void
  userEmail: string
  setUserEmail: (email: string) => void
  step: StepProps
  setStep: (step: StepProps | Object) => void
  validacaoExternaPJ: boolean
  setValidacaoExternaPJ: (validacaoExternaDadosClientePJ: boolean) => void
}

const initialValue = {
  type: '',
  step: {
    pf: 0,
    pj: 0
  },
  userPF: {
    cpf: '',
    nome: '',
    dataNascimento: '',
    sexo: '',
    senha: '',
    token: '4a0c45d784324c03acf4f8236180e849',
    guidJwt: '',
    apelido: '',
    receberNovidades: '',
    receberWhatsApp: '',
    email: '',
    rg: '',
    rgOrgaoEmissor: '',
    telefoneResidencialDDD: '',
    telefoneResidencialNumero: '',
    telefoneCelularNumero: '',
    telefoneCelularDDD: '',
    telefoneComercialDDD: '',
    telefoneComercialNumero: '',
    telefoneComercialRamal: '',
    endereco: {
      id: 0,
      padrao: true,
      tipoEndereco: 'C',
      apelido: '',
      tipoLogradouro: '',
      logradouro: '',
      pontoReferencia: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: ''
    },
    clienteSocialMediaModel: {
      userId: '',
      providerKey: '',
      loginProvider: '',
      email: '',
      nome: ''
    }
  },
  userPJ: {
    idCargo: '',
    idDepartamento: '',
    senha: '',
    token: '',
    guidJwt: '',
    receberNovidades: '',
    receberWhatsApp: '',
    email: '',
    razaoSocial: '',
    nomeFantasia: '',
    cnpj: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    dataAbertura: '',
    idAtividade: '',
    idSubAtividade: '',
    telefoneComercialDDD: '',
    telefoneComercialNumero: '',
    telefoneComercialRamal: '',
    contato: '',
    numeroFuncionariosAdministrativos: '',
    numeroFuncionariosTotal: '',
    telefoneCelularDDD: '',
    telefoneCelularNumero: '',
    endereco: {
      id: 0,
      padrao: true,
      tipoEndereco: 'C',
      apelido: '',
      tipoLogradouro: '',
      logradouro: '',
      pontoReferencia: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: ''
    },
    clienteSocialMediaModel: {
      userId: '',
      providerKey: '',
      loginProvider: '',
      email: '',
      nome: ''
    }
  }
}

export const UserContext = createContext<UserContextType>({} as UserContextType)

export const UserContextProvider = ({ children }: UserContextProps) => {
  const [userType, setUserType] = useState('')
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [validacaoExternaPJ, setValidacaoExternaPJ] = useState(false)
  const [userPF, setUserPF] = useState<UserPFProps>(initialValue.userPF)
  const [userPJ, setUserPJ] = useState<UserPJProps>(initialValue.userPJ)
  const [step, setStep] = useState<StepProps>(initialValue.step)

  const getUserData = async (type: string) => {
    // const response = await fetch()

    // console.log('New', type)
    console.log(userPF, userPJ)
  }

  const createUser = async (data: string) => {
    // const response = await fetch()

    // console.log('New', type)
    console.log(userPF, userPJ)
  }

  return (
    <UserContext.Provider
      value={{
        userType,
        setUserType,
        userPF,
        setUserPF,
        userPJ,
        setUserPJ,
        getUserData,
        createUser,
        userName,
        setUserName,
        userEmail,
        setUserEmail,
        step,
        setStep,
        validacaoExternaPJ,
        setValidacaoExternaPJ
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
