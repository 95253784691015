import React, { useEffect } from 'react'
import { HiX } from 'react-icons/hi'

import IconAlert from '../../../assets/icons/icon-alert-alert.svg'
import IconAlertError from '../../../assets/icons/icon-alert-error.svg'
import IconAlertInfo from '../../../assets/icons/icon-alert-info.svg'
import IconAlertSuccess from '../../../assets/icons/icon-alert-success.svg'

import { useToast } from '../../../hooks/toast'

import { Container } from './styles'

const icons = {
  info: (
    <img
      src={IconAlertInfo}
      style={{ width: 30, marginRight: 12, height: 30 }}
      alt=""
    />
  ),
  error: (
    <img
      src={IconAlertError}
      style={{ width: 30, marginRight: 12, height: 30 }}
      alt=""
    />
  ),
  success: (
    <img
      src={IconAlertSuccess}
      style={{ width: 30, marginRight: 12, height: 30 }}
      alt=""
    />
  ),
  warning: (
    <img
      src={IconAlert}
      style={{ width: 30, marginRight: 12, height: 30 }}
      alt=""
    />
  )
}

const Toast = ({ message, style }) => {
  const { removeToast } = useToast()

  let removeTime = message.timer || 4000

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id)
    }, removeTime)

    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, message.id, removeTime])

  return (
    <Container
      type={message.type}
      hasDescription={Number(!!message.description)}
      style={style}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <HiX size={24} />
      </button>
    </Container>
  )
}

export default Toast
