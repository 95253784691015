/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup } from '../../Form/styles'
import TextField from '../../TextField'
import { useEffect, useState } from 'react'
import * as mask from '../masks'
import * as val from '../fieldValidator'
import * as S from '../styles'
import cadastro from '../../../api/cadastro'
import Loader from '../../Loader'

const RegisterStep2 = ({
  formValues,
  onChange,
  handleChangeStep,
  handleKeyDown
}) => {
  const {
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    uf,
    invalidCEP
  } = formValues

  const [load, setLoad] = useState(false)
  const [isCepUnico, setIsCepUnico] = useState(false)
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)

  const useActiveElement = () => {
    useState(false) /** Useful when working with autoFocus */
    const [activeElement, setActiveElement] = useState(document.activeElement)

    useEffect(() => {
      const onFocus = (event) => setActiveElement(event.target)
      const onBlur = (event) => setActiveElement(null)

      window.addEventListener('focus', onFocus, true)
      window.addEventListener('blur', onBlur, true)

      return () => {
        window.removeEventListener('focus', onFocus)
        window.removeEventListener('blur', onBlur)
      }
    }, [])

    return {
      activeElement,
      listenersReady
    }
  }

  const { activeElement, listenersReady } = useActiveElement()

  const checkCEPUnico = (cep) => {
    const lastDigits = cep.substr(-3)

    if (lastDigits === '000') {
      setIsCepUnico(true)
    } else {
      setIsCepUnico(false)
    }
  }

  const getCEP = async (CEP, onChange) => {
    setLoad(true)
    let endereco = await cadastro.getCEP(CEP)
    if (endereco.status === 200) {
      onChange(false, 'invalidCEP')
      endereco = await endereco.json()

      onChange(endereco.bairro, 'bairro')
      onChange(endereco.cidade, 'cidade')
      onChange(endereco.endereco === '' ? '' : endereco.endereco, 'logradouro')
      onChange(endereco.tipoLogradouro, 'tipoLogradouro')
      onChange(endereco.uf, 'uf')
    } else if (endereco.status === 204) {
      onChange(true, 'invalidCEP')
    }
    setLoad(false)
  }

  const clearCEP = async (onChange) => {
    onChange('', 'bairro')
    onChange('', 'cidade')
    onChange('', 'logradouro')
    onChange('', 'tipoLogradouro')
    onChange('', 'uf')
  }

  useEffect(() => {
    if (!!cep) {
      // console.log('verificar', cep)
      checkCEPUnico(cep)
    }
  }, [])

  useEffect(() => {
    if (activeElement.name === 'CEP') {
      if (cep?.length === 9 && cep?.substr(-3) === '000') {
        checkCEPUnico(cep)
        getCEP(mask.noMask(cep), onChange)
      } else if (cep?.length === 9) {
        getCEP(mask.noMask(cep), onChange)
      } else {
        clearCEP(onChange)
      }
    }
  }, [cep])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      handleChangeStep(false)
    } else {
      window.history.pushState(null, null, window.location.pathname)
      setBackbuttonPress(false)
    }
  }

  return (
    <>
      {load && <Loader />}
      <S.ContainerEndereco>
        <div className="col-group">
          <div className="col-12" style={{ padding: '0px' }}>
            <div className="col-4">
              <FormGroup
                className="required"
                onBlur={() => {
                  if (cep?.length === 9) {
                    getCEP(mask.noMask(cep), onChange)
                    checkCEPUnico(cep)
                  } else {
                    clearCEP(onChange)
                  }
                }}
              >
                <label htmlFor="CEP">CEP</label>
                <TextField
                  error={
                    formValues.errorTrigger[2] && val.cepVal(cep, invalidCEP)
                  }
                  maxlength={9}
                  name="CEP"
                  value={cep}
                  placeholder="Digite seu CEP"
                  // id="cep"
                  onChange={(e) =>
                    onChange(mask.cepMask(e.target.value), 'cep')
                  }
                  success={!val.cepVal(cep, invalidCEP)}
                />
                <S.NaoSei
                  onClick={() =>
                    window.open(
                      'https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php'
                    )
                  }
                >
                  Não sei meu CEP
                </S.NaoSei>
              </FormGroup>
            </div>
            <div className="col-8">
              <FormGroup className="required">
                <label htmlFor="Logradouro">Logradouro</label>
                <TextField
                  maxlength={40}
                  name="logradouro"
                  value={logradouro}
                  placeholder="Digite seu Logradouro"
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                  onChange={(e) => {
                    if (isCepUnico) {
                      onChange(e.target.value.trimStart(), 'logradouro')
                    }
                  }}
                  onBlur={(e) => {
                    onChange(
                      e.target.value.trimEnd().replace(/  +/g, ' '),
                      'logradouro'
                    )
                  }}
                  error={formValues.errorTrigger[2] && val.sizeVal(logradouro)}
                  success={logradouro?.length > 0 && !val.sizeVal(logradouro)}
                />
              </FormGroup>
            </div>
          </div>
          <div className="col-12" style={{ padding: '0px' }}>
            <div className="col-3">
              <FormGroup className="required">
                <label htmlFor="Numero">Número</label>
                <TextField
                  maxlength={8}
                  name="numero"
                  value={numero}
                  placeholder="Numero"
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                  onChange={(e) =>
                    onChange(mask.noMask(e.target.value), 'numero')
                  }
                  error={formValues.errorTrigger[2] && val.sizeVal(numero)}
                  success={numero?.length > 0 && !val.sizeVal(numero)}
                />
              </FormGroup>
            </div>
            <div className="col-5">
              <FormGroup>
                <label htmlFor="Numero">Complemento</label>
                <TextField
                  maxlength={40}
                  name="complemento"
                  value={complemento}
                  placeholder="Complemento"
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                  onChange={(e) =>
                    onChange(e.target.value.trimStart(), 'complemento')
                  }
                  onBlur={(e) => {
                    onChange(
                      e.target.value.trimEnd().replace(/  +/g, ' '),
                      'complemento'
                    )
                  }}
                  success={complemento?.length > 0 && !val.sizeVal(complemento)}
                />
              </FormGroup>
            </div>
            <div className="col-4">
              <FormGroup className="required">
                <label htmlFor="Bairro">Bairro</label>
                <TextField
                  maxlength={40}
                  name="bairro"
                  value={bairro}
                  placeholder="Bairro"
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                  onChange={(e) => {
                    if (isCepUnico) {
                      onChange(e.target.value.trimStart(), 'bairro')
                    }
                  }}
                  onBlur={(e) => {
                    onChange(
                      e.target.value.trimEnd().replace(/  +/g, ' '),
                      'bairro'
                    )
                  }}
                  // onChange={(e) => onChange(e.target.value, 'bairro')}
                  error={formValues.errorTrigger[2] && val.sizeVal(bairro)}
                  success={bairro?.length > 0 && !val.sizeVal(bairro)}
                />
              </FormGroup>
            </div>
          </div>

          <div className="col-12" style={{ padding: '0px' }}>
            <div className="col-9">
              <FormGroup className="required">
                <label htmlFor="Cidade">Cidade</label>
                <TextField
                  maxlength={40}
                  name="cidade"
                  value={cidade}
                  placeholder="Cidade"
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                  // onChange={(e) => onChange(e.target.value, 'cidade')}
                  success={cidade?.length > 0 && !val.sizeVal(cidade)}
                  error={formValues.errorTrigger[2] && val.sizeVal(cidade)}
                />
              </FormGroup>
            </div>
            <div className="col-3">
              <FormGroup className="required">
                <label htmlFor="UF">UF</label>
                <TextField
                  maxlength={2}
                  name="UF"
                  value={uf}
                  placeholder="UF"
                  onKeyDown={(e) => {
                    handleKeyDown(e)
                  }}
                  // onChange={(e) => onChange(e.target.value, 'uf')}
                  success={!val.ufVal(uf)}
                  error={formValues.errorTrigger[2] && val.ufVal(uf)}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </S.ContainerEndereco>
    </>
  )
}

export default RegisterStep2
