import React, { useEffect, useRef } from 'react'
import {
  AccordionIcon,
  AccordionItemStyle,
  AccordionItemTitle
} from '../../styles/accordion'
import { FaUser } from 'react-icons/fa'
import { FaBuilding } from 'react-icons/fa'
import { GiHouse } from 'react-icons/gi'
import { FaPhoneAlt } from 'react-icons/fa'

const AccordionItem = ({
  title,
  children,
  current,
  isActive,
  step1Success,
  step2Success,
  onClick,
  type
}) => {
  const titleRef = useRef(null)

  useEffect(() => {
    if (isActive && titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [isActive])

  const getIcon = (current, type) => {
    if (current === 1) return <FaUser />
    if (current === 2 && type === 'pf') return <GiHouse />
    if (current === 2 && type === 'pj') return <FaBuilding />
    if (current === 3) return <FaPhoneAlt />

    return null
  }

  return (
    <AccordionItemStyle onClick={onClick} ref={titleRef}>
      <AccordionItemTitle>
        <AccordionIcon>
          <div className="icon">{getIcon(current, type)}</div>
          <p>{title}</p>
        </AccordionIcon>
        {step1Success && !isActive ? <p className="success-indicator"></p> : ''}
        {step2Success && !isActive ? <p className="success-indicator"></p> : ''}
      </AccordionItemTitle>
      <div className={`accordion-content ${isActive ? 'active' : ''}`}>
        {children}
      </div>
    </AccordionItemStyle>
  )
}

export default AccordionItem
