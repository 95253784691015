/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup } from '../../Form/styles'
import { useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import TextField from '../../TextField'
import Tooltip from '../../../components/Tooltip/left'
import * as val from '../fieldValidator'
import {
  cpfMask,
  dateMask,
  cellMask,
  fixPhoneMask,
  nameMask,
  preNameMask
} from '../masks'
import {
  birthVal,
  cellVal,
  cpfVal,
  cpfRepetido,
  fixPhoneVal,
  nameVal
} from '../fieldValidator'

import * as S from './styles'
import { useState } from 'react'

const RegisterStep3 = ({
  formValues,
  onChange,
  handleChangeStep,
  handleKeyDown,
  onCaptchaChange
}) => {
  const {
    name,
    likelyName,
    cpf,
    dateOfBirth,
    cellphone,
    phone,
    emails,
    whatsApp,
    acceptTerms,
    cpfCadastrado
  } = formValues

  const [cpfTrigger, setCpfTrigger] = useState(true)
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)

  useEffect(() => {
    if (cpf.length === 14 && cpfTrigger) {
      cpfRepetido(cpf).then((res) => onChange(res, 'cpfCadastrado'))
      setCpfTrigger(false)
    } else if (cpf.length < 14 && !cpfTrigger) {
      setCpfTrigger(true)
    }
  }, [cpf])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      handleChangeStep(false)
    } else {
      window.history.pushState(null, null, window.location.pathname)
      setBackbuttonPress(false)
    }
  }

  return (
    <div className="col-group">
      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-8">
          <FormGroup className="form-group required">
            <label htmlFor="">Nome Completo</label>
            <TextField
              maxlength={50}
              name="name"
              value={name}
              placeholder="Digite seu nome"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(nameMask(e.target.value.trimStart()), 'name')
              }
              onBlur={(e) => {
                onChange(e.target.value.trimEnd().replace(/  +/g, ' '), 'name')
              }}
              error={formValues.errorTrigger[3] && nameVal(name)}
              success={!nameVal(name)}
            />
          </FormGroup>
        </div>
        <div className="col-4">
          <FormGroup>
            <label htmlFor="">Como deseja ser chamado(a)?</label>
            <TextField
              maxlength={20}
              name="likelyName"
              value={likelyName}
              placeholder=""
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(preNameMask(e.target.value.trimStart()), 'likelyName')
              }
              onBlur={(e) => {
                onChange(
                  e.target.value.trimEnd().replace(/  +/g, ' '),
                  'likelyName'
                )
              }}
              success={likelyName?.length > 0 && !val.sizeVal(likelyName)}
            />
          </FormGroup>
        </div>
      </div>

      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-3">
          <FormGroup className="form-group required">
            <label htmlFor="">CPF</label>
            <TextField
              maxlength={15}
              name="cpf"
              value={cpf}
              placeholder="xxx.xxx.xxx-xx"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) => onChange(cpfMask(e.target.value), 'cpf')}
              error={formValues.errorTrigger[3] && cpfVal(cpf, cpfCadastrado)}
              success={!!!cpfVal(cpf, cpfCadastrado)}
            />
          </FormGroup>
        </div>
        <div className="col-3">
          <FormGroup className="form-group required">
            <label htmlFor="">Data de nascimento</label>
            <TextField
              maxlength={10}
              name="dateOfBirth"
              value={dateOfBirth}
              placeholder="dd/mm/aaaa"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(dateMask(e.target.value), 'dateOfBirth')
              }
              error={formValues.errorTrigger[3] && birthVal(dateOfBirth)}
              success={!birthVal(dateOfBirth)}
            />
          </FormGroup>
        </div>

        <div className="col-3">
          <FormGroup className="form-group required">
            <label htmlFor="">Tel. Celular</label>
            <TextField
              maxlength={20}
              name="cellphone"
              value={cellphone}
              placeholder="(xx) xxxxx-xxxx"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) => onChange(cellMask(e.target.value), 'cellphone')}
              error={formValues.errorTrigger[3] && cellVal(cellphone)}
              success={!cellVal(cellphone)}
            />
          </FormGroup>
        </div>
        <div className="col-3">
          <FormGroup className="form-group">
            <label htmlFor="">Tel. Residencial</label>
            <TextField
              maxlength={20}
              name="phone"
              value={phone}
              placeholder="(xx) xxxx-xxxx"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) => onChange(fixPhoneMask(e.target.value), 'phone')}
              error={phone.length > 0 && fixPhoneVal(phone)}
              success={phone.length > 0 && !fixPhoneVal(phone)}
            />
          </FormGroup>
        </div>
      </div>

      <div className="col-12" style={{ padding: '0px' }}>
        <S.CaptchaContainer>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
            onChange={onCaptchaChange}
          ></ReCAPTCHA>
        </S.CaptchaContainer>
      </div>

      <div className="col-12" style={{ padding: '0px' }}>
        <S.TermsWrapper className="cadastro-aceite-termos">
          <FormGroup className="form-group">
            <input
              checked={formValues.whatsApp}
              type="checkbox"
              id="whatsApp"
              onChange={(e) => onChange(!formValues.whatsApp, 'whatsApp')}
            />
            <label htmlFor="whatsApp">
              <div style={{ width: '255px' }}>
                <p>Receber comunicação via Whatsapp </p>
              </div>
            </label>
            <Tooltip
              icon={<i className="icon icon-info"></i>}
              message="Você receberá uma mensagem a cada atualização de pedido, ofertas promocionais do Gimba e parceiros."
            />
          </FormGroup>
          <FormGroup className="form-group">
            <input
              checked={formValues.emails}
              type="checkbox"
              id="email"
              name="emails"
              onClick={() => onChange(!emails, 'emails')}
            />
            <label htmlFor="email">
              Receber e-mails promocionais e de parceiros
            </label>
          </FormGroup>
          <FormGroup className="form-group">
            <input
              checked={formValues.acceptTerms}
              type="checkbox"
              id="termo"
              name="acceptTerms"
              onClick={() => onChange(!acceptTerms, 'acceptTerms')}
            />
            <label htmlFor="termo">
              Aceito os Termos e condições e autorizo o uso de meus dados de
              acordo com a{' '}
              <a
                href={`${process.env.REACT_APP_URL_MAIN}/institucional/seguranca-privacidade`}
                target="_blank"
                rel="noreferrer"
              >
                Declaração de Privacidade
              </a>
            </label>
          </FormGroup>
        </S.TermsWrapper>
      </div>
    </div>
  )
}

export default RegisterStep3
