import axios from 'axios'
import traceLog from '../api/traceLog'

export const traceLogAcessos = async (path) => {
  // const { data } = await axios.get('https://www.myexternalip.com/json')
  // postLogAcessos(data.ip, window.location.href, path)
}

export const getQueryParamsToTracelog = (query) => {
  const currentUrl = window.location.origin + window.location.pathname
  const clientip = query.get('clientip')
  const utmSource = query.get('u_src')
  const utmMedium = query.get('u_med')
  const utmCampaign = query.get('u_camp')
  const redirectUrl = query.get('redirectUrl')

  localStorage.removeItem('u_src')
  // localStorage.removeItem('clientip')
  localStorage.removeItem('u_med')
  localStorage.removeItem('u_camp')

  if (clientip !== null) {
    localStorage.setItem('clientip', clientip)

    // localStorage.getItem('clientip')
  } else {
    if (localStorage.getItem('clientip') === '') {
      localStorage.setItem('clientip', '')
    }
  }

  utmSource !== null && localStorage.setItem('u_src', utmSource)
  utmMedium !== null && localStorage.setItem('u_med', utmMedium)
  utmCampaign !== null && localStorage.setItem('u_camp', utmCampaign)

  // console.log({ utmSource, utmCampaign, utmMedium })
  localStorage.setItem('redirectUrl', redirectUrl)

  // postLogAcessos(clientip, currentUrl, redirectUrl, utmSource, utmMedium, utmCampaign)
}

export const postLogAcessos = async (
  ip,
  currentUrl,
  previousUrl,
  utmSource,
  utmMedium,
  utmCampaign,
  email
) => {
  const body = {
    ip: ip === null ? '' : ip,
    utmSource: utmSource,
    utmMedium: utmMedium,
    utmCampaign: utmCampaign,
    url: currentUrl,
    httpReferer: previousUrl,
    // idLojaEspecial: 0,
    adWords: 0,
    idLojaVirtual: 1,
    EmailCliente: email
  }

  // console.log('POST', body)

  let res = await traceLog.logAcessos(body)
  res = await res.json()
  return res
}

export const postLogAcessosPosCadastro = async (
  currentUrl,
  previousUrl,
  email
) => {
  const body = {
    Url: currentUrl,
    HttpReferer: previousUrl,
    EmailCliente: email
  }

  // console.log('POST', body)

  let res = await traceLog.logAcessosPosCadastro(body)
  res = await res.json()
  return res
}

export const getLocalStorageLog = async (currentPath, email) => {
  const previousUrl = window.location.origin
  const clientip = localStorage.getItem('clientip')
  const utmSource = localStorage.getItem('u_src')
  const utmMedium = localStorage.getItem('u_med')
  const utmCampaign = localStorage.getItem('u_camp')
  let redirectUrl = localStorage.getItem('redirectUrl')

  if (redirectUrl !== undefined && redirectUrl !== null) {
    redirectUrl = window.location.origin + window.location.pathname
  }
  try {
    await postLogAcessos(
      clientip,
      redirectUrl,
      previousUrl,
      utmSource,
      utmMedium,
      utmCampaign,
      email
    )
  } catch (e) {}
}
