export const noMask = (value) => value.replace(/\D/g, '')

export const cpfMask = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export const dateMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2}\/\d{2}\/\d{4})\d+?$/, '$1')
}

export const cellMask = (value) => {
  if (typeof value === 'string')
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(\(\d{2}\)\s\d{5}-\d{4})\d+?$/, '$1')
  return ''
}

export const fixPhoneMask = (value) => {
  if (typeof value === 'string')
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\(\d{2}\)\s\d{4}-\d{4})\d+?$/, '$1')
  return ''
}

export const dddString = (value) => noMask(value || '').substr(0, 2)

export const phoneString = (value) => noMask(value || '').substr(2)

export const dateString = (value) => {
  const date = value?.split('/') || ['01', '01', '2000']
  return `${date[2]}-${date[1]}-${date[0]}T20:29:13.070Z`
}

export const dateFromString = (value) => {
  const date = value?.slice(0, 10).split('-') || ['01', '01', '2000']
  return `${date[2]}/${date[1]}/${date[0]}`
}

export const cepMask = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/^([\d]{5}-[\d]{3})\d+?$/, '$1')

export const addressNumber = (value) => {
  return value.replace(/\D/g, '')
}

export const preNameMask = (value) => {
  return value.replace(/[^A-Za-z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+/g, '')
}

export const nameMask = (value) => {
  return value.replace(/[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+/g, '')
}

