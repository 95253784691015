import * as S from './styles'


const Tooltip = ({ icon, label, message }) => {
  const TooltipBody = () => (
    <S.WrapperL>
      {!!icon && icon}
      <S.TooltipBox>{message}</S.TooltipBox>
    </S.WrapperL>
  )

  if (label) {
    return (
      <S.LabelTooltip htmlFor="whatsapp">
        {label}
        <TooltipBody />
      </S.LabelTooltip>
    )
  }

  return <TooltipBody />
}

export default Tooltip
