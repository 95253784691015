import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CadastroCard from '../../components/CadastroCard'
import Header from '../../components/Header'
import { getLocalStorageLog } from '../../utils/traceLogAcessos'

function End() {
  const { state, pathname } = useLocation()

  async function saveNewUserLog() {
    await getLocalStorageLog(pathname, state?.email)
    setTimeout(
      () =>
        (window.location.href = `${process.env.REACT_APP_URL_MAIN}/Checkout/Login/`),
      2000
    )
  }

  useEffect(() => {
    saveNewUserLog()
  }, [])

  return (
    <>
      <Header onlyLogo></Header>
      <CadastroCard />
    </>
  )
}

export default End
