import styled, {keyframes} from 'styled-components'

const loader = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const loaderDot = keyframes`
  80%, 100% {
    transform: rotate(360deg);
  }
`

const loaderDotBefore = keyframes`
    50% {
      transform: scale(0.4);
    }
    100%, 0% {
      transform: scale(1.0);
    }
`
export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const LoaderContainer = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  z-index: 1001;
  animation: ${loader} 2.5s infinite linear both;
`

export const LoaderDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${loaderDot} 2.0s infinite ease-in-out both;

  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #d22630;
    border-radius: 100%;
    animation: ${loaderDotBefore} 2.0s infinite ease-in-out both;
  }

  &:nth-child(1) {
    animation-delay: -1.1s;
  }

  &:nth-child(2) {
      animation-delay: -1.0s;
  }

  &:nth-child(3) {
      animation-delay: -0.9s;
  }

  &:nth-child(4) {
      animation-delay: -0.8s;
  }

  &:nth-child(5) {
      animation-delay: -0.7s;
  }

  &:nth-child(6) {
      animation-delay: -0.6s;
  }

  &:nth-child(1):before {
      animation-delay: -1.1s;
  }

  &:nth-child(2):before {
      animation-delay: -1.0s;
  }

  &:nth-child(3):before {
      animation-delay: -0.9s;
  }

  &:nth-child(4):before {
      animation-delay: -0.8s;
  }

  &:nth-child(5):before {
      animation-delay: -0.7s;
  }

  &:nth-child(6):before {
      animation-delay: -0.6s;
  }
`

