import styled, { css } from 'styled-components'

export const Wrapper = styled.header`
  ${({ theme, onlyLogo }) => css`
    background: #fff;
    padding: 20px 0;
    position: relative;

    ${onlyLogo &&
    css`
      border-bottom: 1px solid #eeeeee;
    `}

    @media (max-width: ${theme.media.ipad}) {
      padding: 13px;
    }
  `}
`

export const Header = styled.div`
  background-color: white;
  border-bottom: 1px solid #ccc;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 0px 50px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin: 0px 10px;
    padding: 0px;
  }
`

export const Logo = styled.img`
  width: 125px;
  @media (max-width: 600px) {
    width: 95px;
  }
`

export const NavLinks = styled.div`
  a {
    color: #4a4a4a;
    text-decoration: none;
    margin-left: 20px;
    font-size: 1.4rem;
    font-weight: 400;
    @media (max-width: 600px) {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  a:hover {
    color: #d22630;
    cursor: pointer;
    font-weight: 600;
  }
`
