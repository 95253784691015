import { css } from 'styled-components'

import IconSearch from '../assets/icons/icon-search.svg'
import IconMenu from '../assets/icons/icon-menu.svg'
import IconProfile from '../assets/icons/icon-profile.svg'
import IconWishlist from '../assets/icons/icon-whishlist.svg'
import IconArrowDown from '../assets/icons/icon-arrow-down.svg'
import IconAssinatura from '../assets/icons/icon-assinatura.svg'
import IconContratos from '../assets/icons/icon-contratos.svg'
import IconLocal from '../assets/icons/icon-local.svg'
import IconInfo from '../assets/icons/icon-info.svg'
import IconFooterFacebook from '../assets/icons/icon-footer-facebook.svg'
import IconFooterTwitter from '../assets/icons/icon-footer-twitter.svg'
import IconFooterInstagram from '../assets/icons/icon-footer-instagram.svg'
import IconFooterLinkedin from '../assets/icons/icon-footer-linkedin.svg'
import IconFooterYoutube from '../assets/icons/icon-footer-youtube.svg'
import IconCart from '../assets/icons/icon-cart.svg'
import IconCheck from '../assets/icons/icon-check.svg'
import IconClose from '../assets/icons/icon-close.svg'
import IconEye from '../assets/icons/icon-eye.svg'
import IconEyeCut from '../assets/icons/invisible.svg'
import IconArrowBtnRight from '../assets/icons/icon-arrow-button-right.svg'
import IconArrowBtnLeft from '../assets/icons/icon-arrow-button-left.svg'

export const IconStyles = (theme) => css`
  // Icons.
  .icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 30px;
    height: 30px;
  }

  // Search.
  .icon-search {
    background-image: url(${IconSearch});
  }

  // Search.
  .icon-menu {
    background-image: url(${IconMenu});
  }

  // Profile.
  .icon-profile {
    background-image: url(${IconProfile});
  }

  // Whishlist.
  .icon-whishlist {
    background-image: url(${IconWishlist});
  }

  // Arrow Down.
  .icon-arrow-down {
    background-image: url(${IconArrowDown});
    width: 12px;
    height: 8px;
    margin-left: 3px;
  }

  // Assinatura.
  .icon-assinatura {
    background-image: url(${IconAssinatura});
  }

  // Contratos.
  .icon-contratos {
    background-image: url(${IconContratos});
  }

  // Local.
  .icon-local {
    background-image: url(${IconLocal});
  }

  // Footer Youtube.
  .icon-info {
    background-image: url(${IconInfo});
  }

  // Footer Facebook.
  .icon-footer-facebook {
    background-image: url(${IconFooterFacebook});
  }

  // Footer Twitter.
  .icon-footer-twitter {
    background-image: url(${IconFooterTwitter});
  }

  // Footer Instagram.
  .icon-footer-instagram {
    background-image: url(${IconFooterInstagram});
  }

  // Footer Linkedin.
  .icon-footer-linkedin {
    background-image: url(${IconFooterLinkedin});
  }

  // Footer Youtube.
  .icon-footer-youtube {
    background-image: url(${IconFooterYoutube});
  }

  // *** Mask.

  // Cart.
  .icon-cart {
    mask-image: url(${IconCart});
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  // Check.
  .icon-check {
    mask-image: url(${IconCheck});
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  // Close.
  .icon-close {
    mask-image: url(${IconClose});
    mask-size: contain;
    mask-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-color: #707070;
  }

  // Eye.
  .icon-eye {
    mask-image: url(${IconEye});
    mask-size: contain;
    mask-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-color: #707070;
  }

  .icon-eye-cut {
    mask-image: url(${IconEyeCut});
    mask-size: contain;
    mask-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-color: #707070;
  }

  // Arrow Button Right.
  .icon-arrow-button-right {
    mask-image: url(${IconArrowBtnRight});
    mask-size: contain;
    mask-repeat: no-repeat;
    width: 20px;
    height: 10px;
    background-color: #ffffff;
  }

  // Arrow Button Left.
  .icon-arrow-button-left {
    mask-image: url(${IconArrowBtnLeft});
    mask-size: contain;
    mask-repeat: no-repeat;
    width: 20px;
    height: 10px;
    background-color: ${theme.colors.primary};
  }
`
// Arrow Button Right.
export const IconArrowButtonRight = css`
  mask-image: url(${IconArrowBtnRight});
  mask-size: contain;
  mask-repeat: no-repeat;
  width: 20px;
  height: 10px;
  background-color: #ffffff;
`

// Arrow Button Left.
export const IconArrowButtonLeft = (theme) => css`
  mask-image: url(${IconArrowBtnLeft});
  mask-size: contain;
  mask-repeat: no-repeat;
  width: 20px;
  height: 10px;
  background-color: ${theme.colors.primary};
`
