import clientes from '../../api/clientes'

export const cpfRepetido = async (documento) => {
  const res = await clientes.validarDocumento(documento)
  const body = await res.json()
  localStorage.setItem('preCadastro', 'false')
  if (!!body.preCadastro) {
    localStorage.setItem('preCadastro', 'true')
    return undefined
  }
  if (!!body.existe) {
    return 'CPF já cadastrado'
  }
  return undefined
}

export const cpfRepetidoVal = (value) => {
  return value
}

export const emailVal = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
    ? undefined
    : 'Digite um e-mail válido'
}

export const emailConfVal = (email, confEmail = '') => {
  if (confEmail?.length < 1) return 'Preencher campo obrigatório'
  if (email?.match(/@/gi)?.length !== 1) return 'Os e-mails devem ser iguais'
  return email !== confEmail ? 'Os e-mails devem ser iguais' : undefined
}

export const passVal = (value) => {
  return value?.length < 8 ? 'Senha deve ter mais de 8 digitos' : undefined
}

export const passValid = (password, passwordConf) => {
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const specialCharRegex = /[!@#$%^*&]/
  const numberRegex = /\d/
  if (password !== passwordConf) {
    return false
  }
  if (password.length < 8) {
    return false
  }
  if (!uppercaseRegex.test(password)) {
    return false
  }
  if (!lowercaseRegex.test(password)) {
    return false
  }
  if (!specialCharRegex.test(password)) {
    return false
  }
  if (!numberRegex.test(password)) {
    return false
  }
  return true
}

export const passConfVal = (pass, passConf) => {
  if (pass) {
    const valido = passValid(pass, passConf)
    return !valido ? 'Campos preenchidos incorretamente' : undefined
  }
}

export const validatePassword = (password, fieldName, confirmPassword) => {
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const specialCharRegex = /[(!@#$%^*&)]/
  const numberRegex = /\d/

  const passwordValidation = {
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
    length: false,
    matchConfirmation: false
  }

  if (fieldName === 'password' && password !== undefined) {
    passwordValidation.uppercase = uppercaseRegex.test(password)
    passwordValidation.lowercase = lowercaseRegex.test(password)
    passwordValidation.specialChar = specialCharRegex.test(password)
    passwordValidation.number = numberRegex.test(password)
    passwordValidation.length = password.length >= 8
    passwordValidation.matchConfirmation = password === confirmPassword
  }

  if (fieldName === 'confirmPassword') {
    passwordValidation.matchConfirmation = password === confirmPassword
  }

  return passwordValidation
}

export const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]/g, '')

  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false
  }

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let digit1 = 11 - (sum % 11)
  if (digit1 === 10 || digit1 === 11) {
    digit1 = 0
  }
  if (digit1 !== parseInt(cpf.charAt(9))) {
    return false
  }

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }
  let digit2 = 11 - (sum % 11)
  if (digit2 === 10 || digit2 === 11) {
    digit2 = 0
  }
  if (digit2 !== parseInt(cpf.charAt(10))) {
    return false
  }

  return true
}

export const cpfVal = (value = '', extraError = undefined) => {
  if (value?.length < 1) return 'Preencher campo obrigatório'
  if (value?.length < 14) return 'CPF deve ser composto de 11 dígitos'
  if (!validarCPF(value)) return 'CPF inválido'
  if (extraError !== undefined) return extraError
  return undefined
}

export const birthVal = (value = '') => {
  if (!value) return 'Preencher campo obrigatório'

  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/
  if (!dateRegex.test(value)) return 'Formato de data inválido'

  const [day, month, year] = value.split('/').map(Number)

  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth() + 1

  if (year > currentYear) return 'Favor preencher com ano válido'
  if (year === currentYear && month > currentMonth)
    return 'Favor preencher com data válida'

  if (year < 1900 || year > currentYear - 10)
    return 'Ano de nascimento inválido'
  if (month < 1 || month > 12) return 'O mês deve estar entre 01 e 12'
  if (day < 1 || day > 31) return 'O dia deve estar entre 01 e 31'

  const daysInMonth = new Date(year, month, 0).getDate()
  if (day > daysInMonth) return 'Número de dias inválido para o mês escolhido'

  return undefined
}

export const validDDDs = [
  11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
  37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 65,
  66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89,
  91, 92, 93, 94, 95, 96, 97, 98, 99
]

export const cellVal = (value) => {
  if (!value || value.length < 1) return 'Preencher campo obrigatório'

  const sanitizedValue = value.replace(/[^\d]/g, '')

  const repeatedDigitRegex = /^(.)\1{10}$/
  if (repeatedDigitRegex.test(sanitizedValue)) {
    return 'Número de celular inválido'
  }

  if (sanitizedValue.length < 11) {
    return 'Número de celular incompleto'
  }

  const ddd = parseInt(sanitizedValue.substring(0, 2), 10)
  if (!validDDDs.includes(ddd)) {
    return 'DDD inválido'
  }

  return undefined
}

export const fixPhoneVal = (value) => {
  if (!value) return undefined

  const sanitizedValue = value.replace(/[^\d]/g, '')

  if (sanitizedValue.length < 10) {
    return 'Número de telefone incompleto'
  }

  const repeatedDigitRegex = /^(.)\1{9}$/
  if (repeatedDigitRegex.test(sanitizedValue)) {
    return 'Número de telefone inválido'
  }

  const ddd = parseInt(sanitizedValue.substring(0, 2), 10)
  if (!validDDDs.includes(ddd)) {
    return 'DDD inválido'
  }

  return undefined
}

export const nameVal = (value = '') => {
  if (value?.length < 3) return 'Preencher campo obrigatório'
  let arr = value?.split(/\s/)
  if (arr.length < 2) return 'Preencher nome completo'
  if (arr[1].length < 2) return 'Preencher nome completo'
  return undefined
}

export const preNameVal = (value = '') => (value?.length <= 0 ? '' : undefined)

export const fieldVal = (arr, val) => {
  let message = ''
  for (let i = 0; i < arr.length; i++) {
    if (val[i](arr[i])) message += val[i](arr[i]) + '. '
  }
  return message
}

export const fieldValComp = (arr, val) => {
  let message = ''
  for (let i = 0, k = 0; i < val.length; i++, k += 2) {
    if (val[i](arr[k], arr[k + 1])) message += val[i](arr[k], arr[k + 1]) + '. '
  }
  return message
}

export const cepVal = (val, invalid = false) => {
  if (invalid) return 'CEP inválido'
  return val?.length !== 9 ? 'Preencher com CEP válido' : undefined
}

export const ufVal = (val) => {
  const res = val?.replace(/[^a-zA-Z]/g, '').toLowerCase()

  if (
    (res === 'ro' ||
      res === 'ac' ||
      res === 'am' ||
      res === 'rr' ||
      res === 'pa' ||
      res === 'ap' ||
      res === 'to' ||
      res === 'ma' ||
      res === 'pi' ||
      res === 'ce' ||
      res === 'rn' ||
      res === 'pb' ||
      res === 'pe' ||
      res === 'al' ||
      res === 'se' ||
      res === 'ba' ||
      res === 'mg' ||
      res === 'es' ||
      res === 'rj' ||
      res === 'sp' ||
      res === 'pr' ||
      res === 'sc' ||
      res === 'rs' ||
      res === 'ms' ||
      res === 'mt' ||
      res === 'go' ||
      res === 'df') &&
    res.length === 2
  )
    return undefined
  return 'UF inválida'
}
export const sizeVal = (val = '', size = 1) =>
  val?.length < size ? 'Preencher campo obrigatório' : undefined

export const logradouroVal = (val) => {
  let arr = val?.split(/\s/) || []
  if (arr?.length < 2) return 'Preencher logradouro'
  if (arr[1].length < 2 || arr[0].length < 2) return 'Preencher logradouro'
  return undefined
}

export const sexVal = (val) => (val === '' ? 'Selecionar sexo' : undefined)
