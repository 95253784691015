/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import cadastro from '../../api/cadastro'
import { RegisterStep1, RegisterStep2, RegisterStep3 } from './Steps'
import { useSelector } from 'react-redux'
import clientes from '../../api/clientes'
import Loader from '../Loader'
import { useToast } from '../../hooks/toast'
import { Wrapper } from '../PreRegisterForm/styles'
import * as S from './styles'
import {
  cellVal,
  cpfVal,
  emailConfVal,
  emailVal,
  fixPhoneVal,
  nameVal,
  preNameVal,
  fieldVal,
  passVal,
  passConfVal,
  fieldValComp,
  cepVal,
  sizeVal,
  ufVal,
  sexVal,
  cpfRepetidoVal
} from './fieldValidator'
import {
  dateString,
  dddString,
  phoneString,
  noMask
} from '../../components/FormPF/masks'
import { openVal } from '../../components/FormPJ/fieldValidator'
import AccordionItem from '../AccordionItem'
import AccordionButton from '../AccordionButton'
import { AccordionItens, AccordionMenu } from '../../styles/accordion'

const FormPF = ({ type, email, setType, name, setSelectedType }) => {
  const dados = useSelector((store) => store.facebook.dados)
  let { addToast } = useToast()
  let router = useHistory()
  const [trigger, setTrigger] = useState(true)
  const [send, setSend] = useState(false)
  const [current, setCurrent] = useState(1)
  const [load, setLoad] = useState(false)
  const [formValues, setFormvalues] = useState({
    email: '',
    emailConfirm: '',
    password: '',
    passwordConfirm: '',
    name: '',
    likelyName: '',
    cpf: '',
    dateOfBirth: '',
    genre: '',
    cellphone: '',
    phone: '',
    whatsApp: false,
    emails: false,
    acceptTerms: false,
    errorTrigger: { 1: false, 2: false, 3: false },
    cpfCadastrado: undefined
  })
  const [captcha, setCaptcha] = useState({
    value: '',
    expired: false
  })

  const titleRefs = useRef([useRef(null), useRef(null), useRef(null)])

  const handleChange = (value, field) => {
    setFormvalues((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const handleAccordionClick = async (newActiveAccordion) => {
    if (newActiveAccordion !== current) {
      // Lógica para verificar se os passos anteriores foram completados com sucesso
      if (newActiveAccordion === 2 && !step1Success) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Preencher passo anterior corretamente'
        })
        return
      }
      if (newActiveAccordion === 3 && (!step1Success || !step2Success)) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Preencher passos anteriores corretamente'
        })
        return
      }
      const success = await handleChangeStep(true)
      if (success) {
        setCurrent(newActiveAccordion)
      }
    }
  }
  const onCaptchaChange = (value) => {
    if (value === null) {
      setCaptcha({
        value: value,
        expired: true
      })
    } else {
      setCaptcha({ value: value, expired: false })
    }
  }

  useEffect(() => {
    const { nome } = dados
    if (nome.length > 0) handleChange(nome, 'name')
    setSelectedType('pf')
  }, [])

  useEffect(() => {
    if (email)
      setFormvalues((prev) => ({
        ...prev,
        email
      }))
    if (name)
      setFormvalues((prev) => ({
        ...prev,
        name
      }))
  }, [email, name])

  const handleSubmit = async () => {
    setLoad(true) // Ativa o loader
    const {
      emailConfirm,
      likelyName,
      cpf,
      dateOfBirth,
      cellphone,
      phone,
      password,
      passwordConfirm,
      emails,
      whatsApp,
      acceptTerms,
      cpfCadastrado
    } = formValues
    const msg =
      fieldVal(
        [
          cpfCadastrado,
          formValues.email,
          formValues.name,
          likelyName,
          cpf,
          dateOfBirth,
          cellphone,
          phone,
          password
        ],
        [
          cpfRepetidoVal,
          emailVal,
          nameVal,
          preNameVal,
          cpfVal,
          openVal,
          cellVal,
          fixPhoneVal,
          passVal,
          sexVal
        ]
      ) +
      fieldValComp(
        [formValues.email, emailConfirm, password, passwordConfirm],
        [emailConfVal, passConfVal]
      )

    if (msg.length > 1) {
      setLoad(false) // Desativa o loader
      return addToast({
        type: 'error',
        title: 'Preencha os campos corretamente',
        description: ''
      })
    } else if (!acceptTerms) {
      setLoad(false) // Desativa o loader
      return addToast({
        type: 'error',
        title: 'Termos e condições não aceito',
        description: 'Aceitar os Termos e condições'
      })
    } else if (captcha.value === '') {
      setLoad(false) // Desativa o loader
      addToast({
        type: 'error',
        title: 'Por favor, complete a verificação "Não sou um Robô"'
      })
    } else if (captcha.expired === true) {
      setLoad(false) // Desativa o loader
      addToast({
        type: 'error',
        title:
          'Verificação expirada. Por favor, marque novamente a caixa de seleção'
      })
    } else {
      try {
        const formBody = {
          cpf: cpf,
          nome: formValues.name,
          dataNascimento: dateString(dateOfBirth),
          senha: password,
          token: '4a0c45d784324c03acf4f8236180e849',
          guidJwt: captcha.value,
          apelido: likelyName,
          receberNovidades: emails,
          receberWhatsApp: whatsApp,
          email: formValues.email,
          rg: '',
          rgOrgaoEmissor: '',
          telefoneResidencialDDD: dddString(phone),
          telefoneResidencialNumero: phoneString(phone),
          telefoneCelularNumero: phoneString(cellphone),
          telefoneCelularDDD: dddString(cellphone),
          telefoneComercialDDD: '',
          telefoneComercialNumero: '',
          telefoneComercialRamal: '',
          endereco: {
            id: 0,
            padrao: true,
            tipoEndereco: 'C',
            tipoLogradouro: formValues.tipoLogradouro,
            logradouro: formValues.logradouro,
            pontoReferencia: formValues.pontoReferencia,
            numero: formValues.numero,
            complemento: formValues.complemento,
            bairro: formValues.bairro,
            cidade: formValues.cidade,
            uf: formValues.uf,
            cep: noMask(formValues.cep)
          },
          clienteSocialMediaModel: {
            userId: dados.facebookId,
            providerKey: '',
            loginProvider: '',
            email: formValues.email,
            nome: formValues.name
          }
        }

        setTrigger(false)

        let res

        if (JSON.parse(localStorage.getItem('preCadastro'))) {
          res = await cadastro.preCadastroClientePF(formBody)
        } else {
          res = await cadastro.cadastroClientePF(formBody)
        }
        const { status } = res

        if (status >= 200 && status < 300) {
          setLoad(false) // Desativa o loader
          return router.push('/cadastro-sucesso', {
            email: formBody.email
          })
        } else {
          setTrigger(true)
          setLoad(false) // Desativa o loader
          return addToast({
            type: 'Erro',
            title: 'error',
            description:
              res.errors.Messages[0] ||
              'Ocorreu um problema, tente mais tarde novamente'
          })
        }
      } catch (e) {
        setLoad(false) // Desativa o loader em caso de erro
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um problema durante o cadastro. Tente novamente mais tarde.'
        })
      }
    }
  }
  const [step1Success, setStep1Success] = useState(false)
  const [step2Success, setStep2Success] = useState(false)
  const handleChangeStep = async (forwards) => {
    if (current === 3 && forwards && trigger) {
      handleChange({ 1: true, 2: true, 3: true }, 'errorTrigger')

      return false
    } else if (current === 1 && !forwards) {
      setType(undefined)
      return true
    } else if (current === 1 && forwards) {
      handleChange({ 1: true, 2: false, 3: false }, 'errorTrigger')
      const msg =
        fieldVal([formValues.email, formValues.password], [emailVal, passVal]) +
        fieldValComp(
          [
            formValues.email,
            formValues.emailConfirm,
            formValues.password,
            formValues.passwordConfirm
          ],
          [emailConfVal, passConfVal]
        )
      if (msg.length > 1) {
        addToast({
          type: 'error',
          title: 'Preencher campos corretamente'
        })
        return false
      }
      if (JSON.parse(localStorage.getItem('preCadastro'))) {
        setLoad(true)
        let valid
        try {
          valid = await clientes.email(formValues.email)
        } catch (e) {}
        if (valid?.status === 200) {
          setLoad(false)
          addToast({
            type: 'error',
            title: 'E-mail já cadastrado'
          })
          return false
        }
        setLoad(false)
      }
      setStep1Success(true)
      setCurrent(current + 1)
      return true
    } else if (current === 2 && forwards) {
      handleChange({ 1: true, 2: true, 3: false }, 'errorTrigger')
      const msg = fieldVal(
        [
          formValues.logradouro,
          formValues.numero || '',
          formValues.bairro || '',
          formValues.cidade || '',
          formValues.uf,
          formValues.cep || ''
        ],
        [sizeVal, sizeVal, sizeVal, sizeVal, ufVal, cepVal]
      )
      if (msg.length > 1) {
        addToast({
          type: 'error',
          title: 'Preencher campos obrigatórios'
        })
        return false
      }
      setCurrent(current + 1)
      setStep2Success(true)
      return true
    } else if (forwards) {
      setCurrent(current + 1)
      return true
    } else if (!forwards) {
      setCurrent(current - 1)
      return true
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      e.target.blur()
      handleChangeStep(true)
    }
  }

  if (type !== 'pf') return null

  return (
    <>
      {load && <Loader />}
      <Wrapper>
        <S.WrapperMedium>
          <AccordionMenu>
            <AccordionItens>
              <AccordionItem
                title="Dados de Acesso"
                current={1}
                isActive={current === 1}
                step1Success={step1Success}
                onClick={() => handleAccordionClick(1)}
                type={type}
                ref={titleRefs.current[0]}
              >
                <RegisterStep1
                  formValues={formValues}
                  onChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleChangeStep={handleChangeStep}
                />
                <AccordionButton
                  current={current}
                  handleChangeStep={handleChangeStep}
                  trigger={trigger}
                  handleSubmit={handleSubmit}
                />
              </AccordionItem>
              <AccordionItem
                title="Endereço"
                current={2}
                isActive={current === 2}
                step2Success={step2Success}
                onClick={() => handleAccordionClick(2)}
                type={type}
                ref={titleRefs.current[1]}
              >
                <RegisterStep2
                  formValues={formValues}
                  current={current}
                  onChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleChangeStep={handleChangeStep}
                />
                <AccordionButton
                  current={current}
                  handleChangeStep={handleChangeStep}
                  trigger={trigger}
                  handleSubmit={handleSubmit}
                />
              </AccordionItem>
              <AccordionItem
                title="Dados de Contato"
                current={3}
                isActive={current === 3}
                onClick={() => handleAccordionClick(3)}
                type={type}
                ref={titleRefs.current[2]}
              >
                <RegisterStep3
                  formValues={formValues}
                  onChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleChangeStep={handleChangeStep}
                  onCaptchaChange={onCaptchaChange}
                />
                <AccordionButton
                  current={current}
                  handleChangeStep={handleChangeStep}
                  trigger={trigger}
                  handleSubmit={handleSubmit}
                />
              </AccordionItem>
            </AccordionItens>
          </AccordionMenu>
        </S.WrapperMedium>
      </Wrapper>
    </>
  )
}

export default FormPF
