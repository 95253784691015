import axios from 'axios'

interface ReceitaFederalProps {
  idEtapa: 0
  razaoSocial: string
  nomeFantasia: string
  inscricaoMunicipal: string
  inscricaoEstadual: string
  cepFaturamento: string
  numeroEndereco: string
  cnpj: string
  uf: string
}

const cadastro = {
  async cadastroClientePF(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-fisicas`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async preCadastroClientePF(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-fisicasV2`,
      {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async cadastroClientePJ(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-juridicas`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async preCadastroClientePJ(body) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-juridicasV2`,
      {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
    )
  },
  async getCEP(CEP) {
    return await fetch(`${process.env.REACT_APP_API_CEPS}${CEP}`)
  },

  async validarReceitaFederal(body: ReceitaFederalProps) {
    // return await fetch(
    //   `${process.env.REACT_APP_API_CLIENTES}clientes/cnpj-receita-federal`,
    //   {
    //     method: 'post',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(body)
    //   }
    // )

    return axios
      .post(
        `${process.env.REACT_APP_API_CLIENTES}clientes/cnpj-receita-federal`,
        body
      )
      .catch((error) => {
        return error.response
      })
  }
}

export default cadastro
