/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useToast } from '../../hooks/toast'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import clientes from '../../api/clientes'
import Loader from '../Loader'

import { RegisterStep1, RegisterStep2, RegisterStep3 } from './Steps'
import { Wrapper } from '../PreRegisterForm/styles'
import * as S from './styles'
import { dddString, phoneString, noMask } from '../../components/FormPF/masks'
import {
  emailConfVal,
  emailVal,
  fieldVal,
  fieldValComp,
  passConfVal,
  passVal,
  sizeVal,
  ufVal,
  cepVal
} from '../../components/FormPF/fieldValidator'
import {
  cnpjVal,
  comercialPhoneVal,
  contatoVal,
  cellVal,
  razaoVal,
  inscEstVal,
  cnpjRepetidoVal,
  validateInscEstadual
} from './fieldValidator'
import cadastro from '../../api/cadastro.ts'
import { UserContext } from '../../contexts/UserContext'
import AccordionItem from '../AccordionItem'
import AccordionButton from '../AccordionButton'
import { AccordionItens, AccordionMenu } from '../../styles/accordion'

const FormPJ = ({ props, type, email, setType, name, setSelectedType }) => {
  const dados = useSelector((store) => store.facebook.dados)
  let { addToast } = useToast()
  let router = useHistory()
  const [trigger, setTrigger] = useState(true)
  const [current, setCurrent] = useState(1)
  const [load, setLoad] = useState(false)
  const { validacaoExternaPJ } = useContext(UserContext)
  const [validInscEst, setValidInscEst] = useState(false)
  const [receitaFederalError, setReceitaFederalError] = useState({
    cep: { status: false, message: '' },
    numero: { status: false, message: '' },
    uf: { status: false, message: '' },
    cnpj: { status: false, message: '' },
    inscricaoEstadual: { status: false, message: '' },
    razaoSocial: { status: false, message: '' }
  })

  const [formValues, setFormvalues] = useState({
    idCargo: -1,
    idDepartamento: -1,
    email: '',
    confirmaEmailContato: '',
    senha: '',
    confirmaSenha: '',
    receberNovidades: false,
    receberEmailMarketing: false,
    receberWhatsApp: false,
    emailLogin: '',
    razaoSocial: '',
    cnpj: '',
    inscricaoEstadual: '',
    telefoneComercialDDD: '',
    telefoneComercialNumero: '',
    contato: '',
    telefoneCelularDDD: '',
    telefoneCelularNumero: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    pontoReferencia: '',
    invalidCEP: false,
    errorTrigger: { 1: false, 2: false, 3: false, 4: false },
    cnpjCadastrado: undefined
  })

  const [captcha, setCaptcha] = useState({
    value: '',
    expired: false
  })

  const handleChange = (value, field) => {
    setFormvalues((prev) => ({
      ...prev,
      [field]: value
    }))

    if (typeof value === 'string') {
      setReceitaFederalError({
        cep: { status: false, message: '' },
        numero: { status: false, message: '' },
        uf: { status: false, message: '' },
        cnpj: { status: false, message: '' },
        inscricaoEstadual: { status: false, message: '' },
        inscricaoMunicipal: { status: false, message: '' },
        nomeFantasia: { status: false, message: '' },
        razaoSocial: { status: false, message: '' }
      })
    }
  }

  const handleReceitaFederalErrorChange = (value, field, message) => {
    setReceitaFederalError((prev) => ({
      ...prev,
      [field]: { status: value, message: message }
    }))
  }

  const handleAccordionClick = async (newActiveAccordion) => {
    if (newActiveAccordion !== current) {
      // Lógica para verificar se os passos anteriores foram completados com sucesso
      if (newActiveAccordion === 2 && !step1Success) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Preencher passo anterior corretamente'
        })
        return
      }
      if (newActiveAccordion === 3 && (!step1Success || !step2Success)) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Preencher passos anteriores corretamente'
        })
        return
      }
      const success = await handleChangeStep(true)
      if (success) {
        setCurrent(newActiveAccordion)
      }
    }
  }

  const onCaptchaChange = (value) => {
    if (value === null) {
      setCaptcha({
        value: value,
        expired: true
      })
    } else {
      setCaptcha({ value: value, expired: false })
    }
  }
  //
  useEffect(() => {
    const { nome } = dados
    if (nome.length > 0) handleChange(nome, 'contato')
  }, [])

  useEffect(() => {
    setSelectedType('pj')
  }, [])

  useEffect(() => {
    if (email)
      setFormvalues((prev) => ({
        ...prev,
        email
      }))
  }, [email])

  async function validatePJData(body) {
    try {
      const response = await cadastro.validarReceitaFederal({
        idEtapa: 1,
        cepFaturamento: body.endereco.cep,
        cnpj: body.cnpj,
        inscricaoEstadual: body.inscricaoEstadual,
        numeroEndereco: body.endereco.numero,
        razaoSocial: body.razaoSocial,
        uf: body.endereco.uf
      })

      if (response.status === 200) {
        return 'valid'
      }

      function filterMessage(message) {
        if (message.includes('Razão Social')) {
          return 'razao_social'
        } else if (message.includes('Inscrição Estadual')) {
          return 'inscricao_estadual'
        } else if (message.includes('CNPJ nao encontrado na Receita Federal')) {
          return 'cnpj'
        } else if (message.includes('CEP de Faturamento')) {
          return 'cep'
        } else if (message.includes('Número do Endereco')) {
          return 'numero'
        } else if (
          message.includes(
            'CNPJ da consulta não cadastrado como contribuinte na UF'
          )
        ) {
          return 'inscricao_estadual'
        }
      }

      if (response.status === 400) {
        addToast({
          type: 'error',
          title: response.data,
          description: '',
          timer: 8000
        })

        switch (filterMessage(response.data)) {
          case 'razao_social':
            handleReceitaFederalErrorChange(true, 'razaoSocial', response.data)
            break

          case 'cnpj':
            handleReceitaFederalErrorChange(true, 'cnpj', response.data)
            break

          case 'inscricao_estadual':
            handleReceitaFederalErrorChange(
              true,
              'inscricaoEstadual',
              response.data
            )
            break

          case 'cep':
            handleReceitaFederalErrorChange(true, 'cep', response.data)
            handleChangeStep()
            break

          case 'numero':
            handleReceitaFederalErrorChange(true, 'numero', response.data)
            handleChangeStep()
            break

          case 'uf':
            handleReceitaFederalErrorChange(true, 'uf', response.data)
            break

          default:
            break
        }
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um erro ao realizar cadastro',
        description: 'API da Receita Federal sem resposta'
      })
    }
  }
  async function createUser(body) {
    let res
    if (JSON.parse(localStorage.getItem('preCadastro'))) {
      res = await cadastro.preCadastroClientePJ(body)
    } else {
      res = await cadastro.cadastroClientePJ(body)
    }

    if (res.status >= 200 && res.status < 300) {
      setLoad(false)
      return router.push('/cadastro-sucesso', {
        email: body.email
      })
    } else {
      setTrigger(true)
      setLoad(false)
      return addToast({
        type: 'error',
        title:
          res?.errors?.Messages[0] ||
          'Ocorreu um problema, tente mais tarde novamente'
      })
    }
  }

  const salvar = async (values) => {
    setLoad(true) // Ativa o loader
    const msg =
      fieldVal(
        [
          values.cnpjCadastrado,
          values.email,
          values.contato,
          values.razaoSocial,
          values.cnpj,
          values.telefoneCelularNumero,
          values.telefoneComercialNumero,
          values.inscricaoEstadual
        ],
        [
          cnpjRepetidoVal,
          emailVal,
          contatoVal,
          razaoVal,
          cnpjVal,
          cellVal,
          comercialPhoneVal,
          inscEstVal
        ]
      ) +
      fieldValComp(
        [
          values.email,
          values.confirmaEmailContato,
          values.senha,
          values.confirmaSenha
        ],
        [emailConfVal, passConfVal]
      )

    if (msg.length > 1) {
      setLoad(false)
      return addToast({
        type: 'error',
        title: 'Preencher campos obrigatórios',
        description: ''
      })
    } else if (!values.aceiteTermos) {
      setLoad(false)
      return addToast({
        type: 'error',
        title: 'Termos e condições não aceito',
        description: 'Aceitar os Termos e condições'
      })
    } else if (values.inscricaoEstadual !== 'ISENTO' && !validInscEst) {
      setLoad(false)
      return addToast({
        type: 'error',
        title: `Inscrição Estadual Inválida para o estado de ${formValues.uf}`
      })
    } else if (captcha.value === '') {
      setLoad(false)
      addToast({
        type: 'error',
        title: 'Por favor, complete a verificação "Não sou um Robô"'
      })
    } else if (captcha.expired === true) {
      setLoad(false)
      addToast({
        type: 'error',
        title:
          'Verificação expirada. Por favor, marque novamente a caixa de seleção'
      })
    } else {
      const body = {
        idCargo: values.idCargo === -1 ? 0 : Number(values.idCargo),
        idDepartamento:
          values.idDepartamento === -1 ? 0 : Number(values.idDepartamento),
        senha: values.senha,
        token: '4a0c45d784324c03acf4f8236180e849',
        guidJwt: captcha.value,
        receberNovidades: values.receberNovidades,
        receberWhatsApp: values.receberWhatsApp,
        email: values.email,
        razaoSocial: values.razaoSocial,
        cnpj: values.cnpj.replace(/[^0-9\s]/gi, ''),
        inscricaoEstadual: values.inscricaoEstadual,
        telefoneComercialDDD: dddString(values.telefoneComercialNumero),
        telefoneComercialNumero: phoneString(values.telefoneComercialNumero),
        contato: values.contato,
        telefoneCelularDDD: dddString(values.telefoneCelularNumero),
        telefoneCelularNumero: phoneString(values.telefoneCelularNumero),
        endereco: {
          id: 0,
          padrao: true,
          tipoEndereco: 'C',
          tipoLogradouro: formValues.tipoLogradouro,
          logradouro: formValues.logradouro,
          pontoReferencia: formValues.pontoReferencia,
          numero: formValues.numero,
          complemento: formValues.complemento,
          bairro: formValues.bairro,
          cidade: formValues.cidade,
          uf: formValues.uf,
          cep: noMask(formValues.cep)
        },
        clienteSocialMediaModel: {
          userId: dados.facebookId,
          providerKey: '',
          loginProvider: '',
          email: values.email,
          nome: values.email
        }
      }

      try {
        setTrigger(false)

        if (validacaoExternaPJ === true) {
          const receitaFederalInfo = await validatePJData(body)
          if (receitaFederalInfo === 'valid') {
            await createUser(body)
            setLoad(false)
          } else {
            setLoad(false)
          }
        } else {
          await createUser(body)
        }

        setTrigger(true)
      } catch (e) {
        setLoad(false)
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um problema durante o cadastro. Tente novamente mais tarde.'
        })
      }
    }
  }

  const [step1Success, setStep1Success] = useState(false)
  const [step2Success, setStep2Success] = useState(false)

  const handleChangeStep = async (forwards) => {
    if (current === 3 && forwards) {
      handleChange({ 1: true, 2: true, 3: true }, 'errorTrigger')
      const msg = fieldVal(
        [
          formValues.telefoneCelularNumero,
          formValues.telefoneComercialNumero,
          formValues.contato
        ],
        [cellVal, comercialPhoneVal, contatoVal]
      )

      if (msg.length > 1) {
        addToast({
          type: 'error',
          title: 'Preencha os campos corretamente',
          description: ''
        })
        return false
      }

      return true
    } else if (current === 1 && !forwards) {
      setType(undefined)
      return true
    } else if (current === 1 && forwards) {
      handleChange({ 1: true, 2: false, 3: false }, 'errorTrigger')
      const msg =
        fieldVal([formValues.email, formValues.senha], [emailVal, passVal]) +
        fieldValComp(
          [
            formValues.email,
            formValues.confirmaEmailContato,
            formValues.senha,
            formValues.confirmaSenha
          ],
          [emailConfVal, passConfVal]
        )
      if (msg.length > 1) {
        addToast({
          type: 'error',
          title: 'Preencha os campos corretamente',
          description: ''
        })
        return false
      }
      if (JSON.parse(localStorage.getItem('preCadastro'))) {
        setLoad(true)
        let valid
        try {
          valid = await clientes.email(formValues.email)
        } catch (e) {}
        if (valid?.status === 200) {
          setLoad(false)
          addToast({
            type: 'error',
            title: 'E-mail já cadastrado'
          })
          return false
        }
        setLoad(false)
      }
      setStep1Success(true)
      setCurrent(current + 1)
      return true
    } else if (current === 2 && forwards) {
      handleChange({ 1: true, 2: true, 3: false }, 'errorTrigger')
      const msg = fieldVal(
        [
          formValues.razaoSocial,
          formValues.cnpj,
          formValues.inscricaoEstadual,
          formValues.logradouro,
          formValues.numero || '',
          formValues.bairro || '',
          formValues.cidade || '',
          formValues.uf,
          formValues.cep || ''
        ],
        [
          razaoVal,
          cnpjVal,
          inscEstVal,
          sizeVal,
          sizeVal,
          sizeVal,
          sizeVal,
          ufVal,
          cepVal
        ]
      )
      if (
        formValues.inscricaoEstadual !== 'ISENTO' &&
        formValues.inscricaoEstadual?.length >= 8 &&
        formValues.uf
      ) {
        const result = await validateInscEstadual(
          formValues.inscricaoEstadual,
          formValues.uf
        )
        if (!result.valid) {
          addToast({
            type: 'error',
            title: 'Inscrição Estadual inválida para o estado selecionado'
          })
          return false
        }
      }
      console.log(msg)
      if (msg.length > 1) {
        addToast({
          type: 'error',
          title: 'Preencher campos obrigatórios'
        })
        return false
      }

      setStep2Success(true)
      setCurrent(current + 1)
      return true
    } else if (forwards) {
      setCurrent(current + 1)
      return true
    } else if (!forwards) {
      setCurrent(current - 1)
      return true
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      e.target.blur()
      handleChangeStep(true)
    }
  }

  if (type !== 'pj') return null

  return (
    <>
      {load && <Loader />}
      <Wrapper>
        <S.WrapperMedium>
          <AccordionMenu>
            <AccordionItens>
              <AccordionItem
                title="Dados de Acesso"
                current={1}
                isActive={current === 1}
                step1Success={step1Success}
                onClick={() => handleAccordionClick(1)}
                type={type}
              >
                <RegisterStep1
                  formValues={formValues}
                  onChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleChangeStep={handleChangeStep}
                />
                <AccordionButton
                  current={current}
                  handleChangeStep={handleChangeStep}
                  trigger={trigger}
                />
              </AccordionItem>
              <AccordionItem
                title="Dados de Faturamento"
                current={2}
                isActive={current === 2}
                step2Success={step2Success}
                onClick={() => handleAccordionClick(2)}
                type={type}
              >
                <RegisterStep2
                  props={props}
                  formValues={formValues}
                  receitaFederalError={receitaFederalError}
                  onChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleChangeStep={handleChangeStep}
                  setValidInscEst={setValidInscEst}
                />
                <AccordionButton
                  current={current}
                  handleChangeStep={handleChangeStep}
                  trigger={trigger}
                />
              </AccordionItem>
              <AccordionItem
                title="Dados de Contato"
                current={3}
                isActive={current === 3}
                onClick={() => handleAccordionClick(3)}
                type={type}
              >
                <RegisterStep3
                  formValues={formValues}
                  onChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleChangeStep={handleChangeStep}
                  onCaptchaChange={onCaptchaChange}
                />
                <AccordionButton
                  current={current}
                  handleChangeStep={handleChangeStep}
                  trigger={trigger}
                  handleSubmit={() => salvar(formValues)}
                />
              </AccordionItem>
            </AccordionItens>
          </AccordionMenu>
        </S.WrapperMedium>
      </Wrapper>
    </>
  )
}

export default FormPJ
