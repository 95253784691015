/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup, FormGroupIcon } from '../../../components/Form/styles'
import {
  emailConfVal,
  emailVal
} from '../../../components/FormPF/fieldValidator'
import TextField from '../../../components/TextField'
import { useState, useEffect } from 'react'
import * as S from './styles'
import { validatePassword } from '../fieldValidator'

const RegisterStep1 = ({
  formValues,
  onChange,
  handleKeyDown,
  handleChangeStep
}) => {
  const { email, confirmaEmailContato, senha, confirmaSenha } = formValues
  const [passView, setPassView] = useState(true)
  const [passConfView, setPassConfView] = useState(true)
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isNewPasswordEmpty, setNewPasswordEmpty] = useState(true)
  const [isNewPasswordValid, setNewPasswordValid] = useState(false)

  const [passwordValidation, setPasswordValidation] = useState({
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
    length: false,
    matchConfirmation: false
  })

  useEffect(() => {
    const isPasswordValid =
      passwordValidation.uppercase &&
      passwordValidation.lowercase &&
      passwordValidation.specialChar &&
      passwordValidation.number &&
      passwordValidation.length &&
      !isNewPasswordEmpty
    setNewPasswordValid(isPasswordValid)

    if (senha !== '' || confirmaSenha !== '') {
      setPasswordValidation(validatePassword(senha, 'senha', confirmaSenha))
    }

    if (senha === '') {
      setNewPasswordEmpty(true)
    }
  }, [senha, confirmaSenha, passwordValidation.matchConfirmation])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'senha') {
      setNewPasswordEmpty(value === '')
      setPasswordValidation(validatePassword(value, 'senha', confirmaSenha))
    }
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      handleChangeStep(false)
    } else {
      window.history.pushState(null, null, window.location.pathname)
      setBackbuttonPress(false)
    }
  }

  return (
    <div className="col-group">
      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-6">
          <FormGroup className="required">
            <label htmlFor="emailContato">E-mail</label>
            <TextField
              maxlength={80}
              name="emailContato"
              value={email}
              placeholder="Digite seu e-mail"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              // onChange={(e) =>
              //   !!JSON.parse(localStorage.getItem('preCadastro')) &&
              //   onChange(e.target.value.trimStart().toLowerCase(), 'email')
              // }
              error={email.length > 2 && emailVal(email)}
              success={!emailVal(email)}
            />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup className="required">
            <label htmlFor="confirmaEmailContato">Confirme seu e-mail</label>
            <TextField
              maxlength={80}
              name="confirmaEmailContato"
              value={confirmaEmailContato}
              type="email"
              placeholder="Confirme seu e-mail"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(
                  e.target.value.trimStart().toLowerCase(),
                  'confirmaEmailContato'
                )
              }
              onBlur={(e) => {
                onChange(
                  e.target.value.trimEnd().replace(/  +/g, ' '),
                  'confirmaEmailContato'
                )
              }}
              error={
                formValues.errorTrigger[1] &&
                emailConfVal(email, confirmaEmailContato)
              }
              success={!emailConfVal(email, confirmaEmailContato)}
            />
          </FormGroup>
        </div>
      </div>
      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-6">
          <FormGroup className="form-group required">
            <label htmlFor="email">Senha</label>
            <FormGroupIcon className="form-group-icon">
              <TextField
                maxlength={80}
                name="senha"
                type={passView ? 'password' : 'text'}
                value={senha}
                placeholder="Digite sua senha"
                success={isNewPasswordValid}
                error={formValues.errorTrigger[1] && !isNewPasswordValid}
                onChange={(e) => {
                  handleChange(e)
                  onChange(e.target.value.trimStart(), 'senha')
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
              />
              <span
                className={!passView ? 'icon icon-eye' : 'icon icon-eye-cut'}
                onClick={() => setPassView(!passView)}
              ></span>
            </FormGroupIcon>
          </FormGroup>
        </div>

        <div className="col-6">
          <FormGroup className="form-group required last-element">
            <label htmlFor="email">Confirme sua senha</label>
            <FormGroupIcon className="form-group-icon">
              <TextField
                maxlength={80}
                name="confirmaSenha"
                type={passConfView ? 'password' : 'text'}
                value={confirmaSenha}
                placeholder="Confirme sua senha"
                success={passwordValidation.matchConfirmation}
                error={
                  formValues.errorTrigger[1] &&
                  !passwordValidation.matchConfirmation
                }
                onChange={(e) => {
                  handleChange(e)
                  onChange(e.target.value.trimStart(), 'confirmaSenha')
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
              />
              <span
                className={
                  !passConfView ? 'icon icon-eye' : 'icon icon-eye-cut'
                }
                onClick={() => setPassConfView(!passConfView)}
              ></span>
              <div></div>
            </FormGroupIcon>
          </FormGroup>
        </div>
      </div>
      <div className="col-12" style={{ padding: '0px' }}>
        <S.ErrorList>
          <S.TitleErrorList>
            Senha deve conter os seguintes parâmetros
          </S.TitleErrorList>
          <S.Error
            isValid={passwordValidation.length}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Ter no mínimo 8 caracteres
          </S.Error>
          <S.Error
            isValid={passwordValidation.uppercase}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Uma letra maiúscula
          </S.Error>
          <S.Error
            isValid={passwordValidation.lowercase}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Uma letra minúscula
          </S.Error>
          <S.Error
            isValid={passwordValidation.number}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Um número
          </S.Error>
          <S.Error
            isValid={passwordValidation.specialChar}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Um caractere especial {'(!@#$%^*&)'}
          </S.Error>
          <S.Error
            isValid={passwordValidation.matchConfirmation}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            A senha e a confirmação devem ser iguais
          </S.Error>
        </S.ErrorList>
      </div>
    </div>
  )
}

export default RegisterStep1
