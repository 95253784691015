import { css } from 'styled-components'

export const Button = (theme) => css`
  border-radius: 5px;
  border: 2px solid transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 15px 30px;
  text-align: center;
  transition: all 400ms;

  & + .button {
    margin-left: 15px;
  }

  @media (max-width: ${theme.media.ipad}) {
    font-size: 14px;
  }
`

export const ButtonPrimary = (theme) => css`
  background: ${theme.colors.primary};
  color: #ffffff;

  &:hover {
    background: darken(${theme.colors.primary}, 10%);
  }
`

export const ButtonBlock = css`
  display: block;
  width: 100%;
`

export const ButtonOutline = (theme) => css`
  background: transparent;
  border: 2px solid ${theme.colors.primary};
  color: ${theme.colors.primary};

  &:hover {
    border-color: #4a4a4a;
    color: #4a4a4a;
  }
`

export const ButtonBack = (theme) => css`
  background: #ffffff;
  border: 2px solid ${theme.colors.primary};
  color: ${theme.colors.primary};

  &:hover {
    border-color: #4a4a4a;
    color: #4a4a4a;
  }
`

export const ButtonIcon = css`
  padding: 15px 40px;
  vertical-align: middle;

  i {
    display: inline-block;

    &.icon-arrow-button-right {
      margin-left: 10px;
    }

    &.icon-arrow-button-left {
      margin-right: 10px;
    }
  }
`
