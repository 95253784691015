/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react'
import { FormGroup } from '../../components/Form/styles'
import TextField from '../../components/TextField'
import { useToast } from '../../hooks/toast'
import clientes from '../../api/clientes'
import Loader from '../Loader'

import * as S from './styles'
import { emailVal } from '../FormPF/fieldValidator'

const PreRegisterForm = ({
  type,
  setType,
  email,
  setEmail,
  setSelectedType,
  selectedType
}) => {
  // const [selectedType, setSelectedType] = useState('pf')
  const [load, setLoad] = useState(false)

  const { addToast } = useToast()
  const inputRef0 = useRef()

  const handleSelectType = (e) => {
    const newValue = e.currentTarget.value
    setSelectedType(newValue)
  }

  const handleChangeEmail = (e) => {
    const newValue = e.target.value
    setEmail(newValue.toLowerCase())
  }

  const handleConfirm = async (e) => {
    e.preventDefault()
    setLoad(true)

    let msg = emailVal(email)

    if (msg !== undefined) {
      setLoad(false)
      return addToast({
        type: 'error',
        title: 'E-mail inválido'
      })
    }

    let valid
    try {
      valid = await clientes.email(email)
    } catch (e) {}
    if (valid?.status === 400) {
      setLoad(false)
      return addToast({
        type: 'error',
        title: 'E-mail já cadastrado'
      })
    }

    setLoad(false)
    return setType(selectedType)
  }

  useEffect(() => {
    !!inputRef0.current && inputRef0.current.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleConfirm(e)
    }
  }

  if (type) return null

  return (
    <>
      {load && <Loader />}
      <S.RegisterWrapper>
        <S.PageContent>
          <S.WrapperSmall>
            <S.RegisterCard>
              <S.Title>Cadastre-se</S.Title>
              <form
                action=""
                id="form-cadastro"
                onSubmit={(e) => {
                  if (!!email) handleConfirm(e)
                }}
              >
                <FormGroup className="form-group">
                  <label htmlFor="email">E-mail</label>
                  <TextField
                    onKeyDown={(e) => {
                      handleKeypress(e)
                    }}
                    reference={inputRef0}
                    maxlength={80}
                    name="email"
                    value={email}
                    type="email"
                    placeholder="Digite seu e-mail"
                    onChange={handleChangeEmail}
                  />
                </FormGroup>
                <S.RegisterColumns className="cadastro-columns">
                  <S.RegisterColumn className="cadastro-column">
                    <FormGroup className="form-group">
                      <input
                        type="radio"
                        name="tipopessoa"
                        id="cadastropf"
                        value="pf"
                        checked={selectedType === 'pf' && true}
                        onChange={handleSelectType}
                      />
                      <label htmlFor="cadastropf">Pessoa Física</label>
                    </FormGroup>
                  </S.RegisterColumn>
                  <S.RegisterColumn className="cadastro-column">
                    <FormGroup className="form-group">
                      <input
                        type="radio"
                        name="tipopessoa"
                        id="cadastropj"
                        checked={selectedType === 'pj' && true}
                        value="pj"
                        onChange={handleSelectType}
                      />
                      <label htmlFor="cadastropj">Pessoa Juridica</label>
                    </FormGroup>
                  </S.RegisterColumn>
                </S.RegisterColumns>
                <FormGroup className="form-group">
                  <S.AdvanceButton
                    disabled={!selectedType || !email}
                    type="submit"
                    // onClick={() => {
                    //   if (!!email) handleConfirm()
                    // }}
                  >
                    Avançar
                  </S.AdvanceButton>
                </FormGroup>
              </form>
              <p className="text-center">
                Já possui cadastro?{' '}
                <strong
                  onClick={() =>
                    (window.location.href = `${process.env.REACT_APP_URL_MAIN}/checkout/login`)
                  }
                  className="link text-color-primary"
                  style={{ cursor: 'pointer' }}
                >
                  Acesse
                </strong>
              </p>
            </S.RegisterCard>
          </S.WrapperSmall>
        </S.PageContent>
      </S.RegisterWrapper>
    </>
  )
}

export default PreRegisterForm
