/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { FormGroup } from '../../Form/styles'
import Select from '../../Select'
import TextField from '../../TextField'
import { contatoVal, cellVal, comercialPhoneVal } from '../fieldValidator'
import { nameMask, cellMask, fixPhoneMask } from '../../FormPF/masks'
import clientes from '../../../api/clientes'
import defaultOptions from '../../../api/defaultoption'
import Tooltip from '../../Tooltip/left'
import ReCAPTCHA from 'react-google-recaptcha'
import { CaptchaContainer } from '../../FormPF/styles'
import * as S from './styles'

const RegisterStep3 = ({
  formValues,
  onChange,
  handleKeyDown,
  handleChangeStep,
  onCaptchaChange
}) => {
  const {
    idCargo,
    idDepartamento,
    contato,
    telefoneCelularNumero,
    telefoneComercialNumero
  } = formValues

  const [cargos, setCargos] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)

  const apiCall = async () => {
    let cargos = await clientes.cargos({
      method: 'GET',
      headers: defaultOptions.headers
    })

    cargos = await cargos.json()
    setCargos(cargos)

    let departamentos = await clientes.departamentos({
      method: 'GET',
      headers: defaultOptions.headers
    })
    departamentos = await departamentos.json()
    setDepartamentos(departamentos)
  }
  useEffect(() => {
    apiCall()
  }, [])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      handleChangeStep(false)
    } else {
      window.history.pushState(null, null, window.location.pathname)
      setBackbuttonPress(false)
    }
  }

  const handleSelectChange = (e, field) => {
    const selectedValue = parseInt(e.currentTarget.value, 10)
    onChange(selectedValue, field)
  }

  return (
    <div className="col-group">
      <div className="col-12">
        <FormGroup className="form-group required">
          <label htmlFor="">Nome</label>
          <TextField
            name="contato"
            maxlength={30}
            value={contato}
            placeholder="Digite seu nome"
            onKeyDown={handleKeyDown}
            onChange={(e) =>
              onChange(nameMask(e.target.value.trimStart()), 'contato')
            }
            error={formValues.errorTrigger[3] && contatoVal(contato)}
            success={!contatoVal(contato)}
          />
        </FormGroup>
      </div>
      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-6">
          <FormGroup className="form-group">
            <Select
              success={idCargo !== -1 && idCargo !== null}
              options={[
                { value: -1, label: '-- Selecione --' },
                ...cargos.map((item) => ({
                  value: item.cargoId,
                  label: item.cargo
                }))
              ]}
              label="Cargo"
              labelFor="Cargo"
              value={idCargo}
              onChange={(e) => handleSelectChange(e, 'idCargo')}
            />
          </FormGroup>
        </div>

        <div className="col-6">
          <FormGroup className="form-group">
            <Select
              success={idDepartamento !== -1 && idDepartamento !== null}
              options={[
                { value: -1, label: '-- Selecione --' },
                ...departamentos.map((item) => ({
                  value: item.departamentoId,
                  label: item.departamento
                }))
              ]}
              label="Departamento"
              labelFor="Departamento"
              value={idDepartamento}
              onChange={(e) => handleSelectChange(e, 'idDepartamento')}
            />
          </FormGroup>
        </div>
      </div>

      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-6">
          <FormGroup className="form-group required">
            <label htmlFor="">Tel. Celular</label>
            <TextField
              name="telefoneCelularNumero"
              value={telefoneCelularNumero}
              placeholder="(xx) xxxxx-xxxx"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(cellMask(e.target.value), 'telefoneCelularNumero')
              }
              error={
                formValues.errorTrigger[3] && cellVal(telefoneCelularNumero)
              }
              success={
                telefoneCelularNumero.length > 0 &&
                !cellVal(telefoneCelularNumero)
              }
            />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup className="form-group required">
            <label htmlFor="">Tel. Comercial</label>
            <TextField
              name="telefoneComercialNumero"
              value={telefoneComercialNumero}
              placeholder="(xx) xxxxx-xxxx"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(
                  fixPhoneMask(e.target.value),
                  'telefoneComercialNumero'
                )
              }
              error={
                formValues.errorTrigger[3] &&
                comercialPhoneVal(telefoneComercialNumero)
              }
              success={
                telefoneCelularNumero.length > 0 &&
                !comercialPhoneVal(telefoneComercialNumero)
              }
            />
          </FormGroup>
        </div>
      </div>

      <div className="col-12" style={{ padding: '0px' }}>
        <CaptchaContainer>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
            onChange={onCaptchaChange}
          ></ReCAPTCHA>
        </CaptchaContainer>
      </div>

      <div className="col-12" style={{ padding: '0px' }}>
        <S.TermsWrapper className="cadastro-aceite-termos">
          <FormGroup className="form-group">
            <input
              checked={formValues.receberWhatsApp}
              type="checkbox"
              id="receberWhatsApp"
              onChange={(e) =>
                onChange(!formValues.receberWhatsApp, 'receberWhatsApp')
              }
            />
            <label htmlFor="receberWhatsApp">
              <div style={{ width: '255px' }}>
                <p>Receber comunicação via Whatsapp </p>
              </div>
            </label>
            <Tooltip
              icon={<i className="icon icon-info"></i>}
              message="Você receberá uma mensagem a cada atualização de pedido, ofertas promocionais do Gimba e parceiros."
            />
          </FormGroup>

          <FormGroup className="form-group">
            <input
              checked={formValues.receberNovidades}
              type="checkbox"
              id="receberNovidades"
              onChange={(e) =>
                onChange(!formValues.receberNovidades, 'receberNovidades')
              }
            />
            <label htmlFor="receberNovidades">
              Receber e-mails promocionais e de parceiros
            </label>
          </FormGroup>
          <FormGroup className="form-group">
            <input
              checked={formValues.aceiteTermos}
              type="checkbox"
              onChange={(e) =>
                onChange(!formValues.aceiteTermos, 'aceiteTermos')
              }
              id="aceiteTermos"
            />
            <label htmlFor="aceiteTermos">
              Aceito os Termos e condições e autorizo o uso de meus dados de
              acordo com a{' '}
              <a
                href={`${process.env.REACT_APP_URL_MAIN}/institucional/seguranca-privacidade`}
                target="_blank"
                rel="noreferrer"
              >
                Declaração de Privacidade
              </a>
            </label>
          </FormGroup>
        </S.TermsWrapper>
      </div>
    </div>
  )
}

export default RegisterStep3
