import { useState, useEffect, useContext } from 'react'
import { FormGroup } from '../../Form/styles'
import TextField from '../../TextField'
import clientes from '../../../api/clientes'
import defaultOptions from '../../../api/defaultoption'
import {
  cnpjVal,
  inscEstVal,
  razaoVal,
  cnpjRepetido,
  validateReceitaError,
  validateInscEstadual
} from '../fieldValidator'
import { cnpjMask, inscrEstMask } from '../masks'
import { UserContext } from '../../../contexts/UserContext'
import * as mask from '../../FormPF/masks'
import { cepVal, sizeVal, ufVal } from '../../FormPF/fieldValidator'
import * as S from '../../FormPF/styles'
import cadastro from '../../../api/cadastro'
import Loader from '../../Loader'
import { LabelInfo } from './styles'

const RegisterStep2 = ({
  formValues,
  receitaFederalError,
  onChange,
  handleKeyDown,
  handleChangeStep,
  setValidInscEst
}) => {
  const {
    razaoSocial,
    cnpj,
    inscricaoEstadual,
    cnpjCadastrado,
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    uf,

    invalidCEP
  } = formValues

  const [cnpjTrigger, setCnpjTrigger] = useState(true)
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)
  const { setValidacaoExternaPJ } = useContext(UserContext)

  const [isInscEstValid, setIsInscEstValid] = useState({
    valid: false,
    message: '',
    showToaster: false
  })

  const [load, setLoad] = useState(false)
  const [isCepUnico, setIsCepUnico] = useState(false)

  const [isUfAutoFilled, setIsUfAutoFilled] = useState(false)
  const [isUfValid, setIsUfValid] = useState(true) // Estado para controlar a validade da UF

  useEffect(() => {
    if (cnpj.length === 18 && cnpjTrigger) {
      cnpjRepetido(cnpj, setValidacaoExternaPJ).then((res) => {
        onChange(res, 'cnpjCadastrado')
      })
      setCnpjTrigger(false)
    } else if (cnpj.length < 18 && !cnpjTrigger) {
      setCnpjTrigger(true)
    }
  }, [cnpj])

  useEffect(() => {
    const validate = async () => {
      const result = await validateInscEstadual(inscricaoEstadual, uf)
      setIsInscEstValid({
        valid: result.valid,
        message: result.message,
        showToaster: true
      })
      setValidInscEst(result.valid)
    }

    if (
      inscricaoEstadual !== 'ISENTO' &&
      inscricaoEstadual?.length >= 8 &&
      uf
    ) {
      validate()
    }
  }, [inscricaoEstadual, uf])

  useEffect(() => {
    if (!!formValues.isento) onChange('ISENTO', 'inscricaoEstadual')
  }, [formValues.isento])

  const useActiveElement = () => {
    const [listenersReady, setListenersReady] = useState(false)
    const [activeElement, setActiveElement] = useState(document.activeElement)

    useEffect(() => {
      const onFocus = (event) => setActiveElement(event.target)
      const onBlur = (event) => setActiveElement(null)

      window.addEventListener('focus', onFocus, true)
      window.addEventListener('blur', onBlur, true)

      setListenersReady(true)

      return () => {
        window.removeEventListener('focus', onFocus)
        window.removeEventListener('blur', onBlur)
      }
    }, [])

    return {
      activeElement,
      listenersReady
    }
  }
  const { activeElement, listenersReady } = useActiveElement()

  const checkCEPUnico = (cep) => {
    const lastDigits = cep.substr(-3)

    if (lastDigits === '000') {
      setIsCepUnico(true)
    } else {
      setIsCepUnico(false)
    }
  }

  const getCEP = async (CEP, onChange) => {
    setLoad(true)
    let endereco = await cadastro.getCEP(CEP)
    if (endereco.status === 200) {
      onChange(false, 'invalidCEP')
      endereco = await endereco.json()
      onChange(endereco.bairro, 'bairro')
      onChange(endereco.cidade, 'cidade')
      onChange(endereco.endereco === '' ? '' : endereco.endereco, 'logradouro')
      onChange(endereco.tipoLogradouro, 'tipoLogradouro')
      onChange(endereco.uf, 'uf')
      setIsUfAutoFilled(true)
    } else if (endereco.status === 204) {
      onChange(true, 'invalidCEP')
    }
    setLoad(false)
  }

  const clearCEP = async (onChange) => {
    onChange('', 'bairro')
    onChange('', 'cidade')
    onChange('', 'logradouro')
    onChange('', 'tipoLogradouro')
    onChange('', 'uf')
    setIsUfAutoFilled(false)
  }

  useEffect(() => {
    if (!!cep) {
      checkCEPUnico(cep)
    }
  }, [])

  useEffect(() => {
    if (activeElement.name === 'CEP') {
      if (cep?.length === 9 && cep?.substr(-3) === '000') {
        checkCEPUnico(cep)
        getCEP(mask.noMask(cep), onChange)
      } else if (cep?.length === 9) {
        getCEP(mask.noMask(cep), onChange)
      } else {
        clearCEP(onChange)
      }
    }
  }, [cep])

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  const handleBlurInscEstadual = async (e) => {
    if (uf) {
      onChange(e.target.value.trimEnd(), 'inscricaoEstadual')
      const result = await validateInscEstadual(e.target.value, uf)
      setIsInscEstValid({
        valid: result.valid,
        message: result.message,
        showToaster: true
      })
      setValidInscEst(result.valid)
    }
  }

  const handleChangeUF = async (e) => {
    const ufValue = e.target.value
    onChange(ufValue, 'uf')

    const isValid = ufVal(ufValue)
    if (isValid !== undefined) {
      setIsUfValid(isValid)
    }
    if (inscricaoEstadual !== 'ISENTO' && inscricaoEstadual?.length >= 8) {
      const result = await validateInscEstadual(inscricaoEstadual, ufValue)
      setIsInscEstValid({
        valid: result.valid,
        message: result.message,
        showToaster: true
      })
      setValidInscEst(result.valid)
    }
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      handleChangeStep(false)
    } else {
      window.history.pushState(null, null, window.location.pathname)
      setBackbuttonPress(false)
    }
  }

  return (
    <>
      {load && <Loader />}
      <div className="col-group">
        <div className="col-12">
          <FormGroup className="form-group required">
            <label htmlFor="">Razão Social</label>
            <TextField
              maxlength={45}
              name="razaoSocial"
              value={razaoSocial}
              placeholder="Digite a razão social"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(e.target.value.trimStart(), 'razaoSocial')
              }
              onBlur={(e) => {
                onChange(
                  e.target.value.trimEnd().replace(/  +/g, ' '),
                  'razaoSocial'
                )
              }}
              error={
                (formValues.errorTrigger[2] && razaoVal(razaoSocial)) ||
                validateReceitaError(
                  receitaFederalError.razaoSocial.status,
                  'Razão Social',
                  receitaFederalError.razaoSocial.message
                )
              }
              success={
                !razaoVal(razaoSocial) &&
                !receitaFederalError.razaoSocial.status
              }
            />
          </FormGroup>
        </div>
        <div className="col-12" style={{ padding: '0px' }}>
          <div className="col-6">
            <FormGroup className="form-group required">
              <label htmlFor="">CNPJ</label>
              <TextField
                maxlength={30}
                name="cnpj"
                value={cnpj}
                placeholder="Digite o cnpj"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) => {
                  onChange(cnpjMask(e.target.value), 'cnpj')
                }}
                error={
                  (formValues.errorTrigger[2] &&
                    cnpjVal(cnpj, cnpjCadastrado)) ||
                  validateReceitaError(
                    receitaFederalError.cnpj.status,
                    'CNPJ',
                    receitaFederalError.cnpj.message
                  )
                }
                success={
                  !cnpjVal(cnpj, cnpjCadastrado) &&
                  !receitaFederalError.cnpj.status
                }
              />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup className="form-group required">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '24px'
                }}
              >
                <label htmlFor="">Inscrição Estadual</label>
                <FormGroup className="form-group">
                  <input
                    checked={formValues.isento}
                    type="checkbox"
                    id="isento"
                    onChange={(e) => {
                      onChange('', 'inscricaoEstadual')
                      return onChange(!formValues.isento, 'isento')
                    }}
                  />
                  <label htmlFor="isento" style={{ marginRight: '1px' }}>
                    <p>Isento </p>
                  </label>
                </FormGroup>
              </div>
              <TextField
                maxlength={15}
                name="inscricaoEstadual"
                value={inscricaoEstadual}
                placeholder="Digite a Inscrição Estadual"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) =>
                  !formValues.isento &&
                  onChange(inscrEstMask(e.target.value), 'inscricaoEstadual')
                }
                onBlur={handleBlurInscEstadual}
                error={
                  (activeElement?.name !== 'inscricaoEstadual' &&
                    formValues.errorTrigger[2] &&
                    formValues.uf !== '' &&
                    inscEstVal(
                      inscricaoEstadual,
                      isInscEstValid.valid,
                      formValues.uf
                    )) ||
                  validateReceitaError(
                    receitaFederalError.inscricaoEstadual.status,
                    'Inscrição Estadual',
                    receitaFederalError.inscricaoEstadual.message
                  )
                }
                success={
                  activeElement?.name !== 'inscricaoEstadual' &&
                  (formValues.isento ||
                    (!inscEstVal(
                      inscricaoEstadual,
                      isInscEstValid.valid,
                      formValues.uf
                    ) &&
                      !receitaFederalError.inscricaoEstadual.status))
                }
              />
            </FormGroup>
          </div>
        </div>{' '}
        <div className="col-12" style={{ padding: '0px' }}>
          <div className="col-4">
            <FormGroup
              className="required"
              onBlur={() => {
                if (cep?.length === 9) {
                  getCEP(mask.noMask(cep), onChange)
                  checkCEPUnico(cep)
                } else {
                  clearCEP(onChange)
                }
              }}
            >
              <label htmlFor="CEP">CEP</label>
              <TextField
                maxlength={9}
                name="CEP"
                value={cep}
                placeholder="Digite seu CEP"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) => onChange(mask.cepMask(e.target.value), 'cep')}
                error={
                  (formValues.errorTrigger[2] && cepVal(cep, invalidCEP)) ||
                  validateReceitaError(
                    receitaFederalError.cep.status,
                    'CEP',
                    receitaFederalError.cep.message
                  )
                }
                success={
                  !cepVal(cep, invalidCEP) && !receitaFederalError.cep.status
                }
              />
              <S.NaoSei
                onClick={() =>
                  window.open(
                    'https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php'
                  )
                }
              >
                Não sei meu CEP
              </S.NaoSei>
            </FormGroup>
          </div>

          <div className="col-8">
            <FormGroup className="required">
              <label htmlFor="Logradouro">Logradouro</label>
              <TextField
                maxlength={40}
                name="logradouro"
                value={logradouro}
                placeholder="Digite seu Logradouro"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) => {
                  if (isCepUnico) {
                    onChange(e.target.value.trimStart(), 'logradouro')
                  }
                }}
                onBlur={(e) => {
                  onChange(
                    e.target.value.trimEnd().replace(/  +/g, ' '),
                    'logradouro'
                  )
                }}
                error={formValues.errorTrigger[2] && sizeVal(logradouro)}
                success={logradouro?.length > 0 && !sizeVal(logradouro)}
              />
            </FormGroup>
          </div>
        </div>{' '}
        <div className="col-12" style={{ padding: '0px' }}>
          <div className="col-4">
            <FormGroup className="required">
              <label htmlFor="Numero">Número</label>
              <TextField
                maxlength={8}
                name="numero"
                value={numero}
                placeholder="Numero"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) => onChange(e.target.value, 'numero')}
                error={
                  (formValues.errorTrigger[2] && sizeVal(numero)) ||
                  validateReceitaError(
                    receitaFederalError.numero.status,
                    'Número',
                    receitaFederalError.numero.message
                  )
                }
                success={
                  numero?.length > 0 &&
                  !sizeVal(numero) &&
                  !receitaFederalError.numero.status
                }
              />
            </FormGroup>
          </div>
          <div className="col-4">
            <FormGroup>
              <label htmlFor="Numero">Complemento</label>
              <TextField
                maxlength={40}
                name="complemento"
                value={complemento}
                placeholder="Complemento"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) =>
                  onChange(e.target.value.trimStart(), 'complemento')
                }
                onBlur={(e) => {
                  onChange(
                    e.target.value.trimEnd().replace(/  +/g, ' '),
                    'complemento'
                  )
                }}
                success={complemento?.length > 0 && !sizeVal(complemento)}
              />
            </FormGroup>
          </div>
          <div className="col-4">
            <FormGroup className="required">
              <label htmlFor="Bairro">Bairro</label>
              <TextField
                maxlength={40}
                name="bairro"
                value={bairro}
                placeholder="Bairro"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={(e) => {
                  if (isCepUnico) {
                    onChange(e.target.value.trimStart(), 'bairro')
                  }
                }}
                onBlur={(e) => {
                  onChange(
                    e.target.value.trimEnd().replace(/  +/g, ' '),
                    'bairro'
                  )
                }}
                error={formValues.errorTrigger[2] && sizeVal(bairro)}
                success={bairro?.length > 0 && !sizeVal(bairro)}
              />
            </FormGroup>
          </div>
        </div>{' '}
        <div className="col-12" style={{ padding: '0px' }}>
          <div className="col-9">
            <FormGroup className="required">
              <label htmlFor="Cidade">Cidade</label>
              <TextField
                maxlength={40}
                name="cidade"
                value={cidade}
                placeholder="Cidade"
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                success={cidade?.length > 0 && !sizeVal(cidade)}
                error={formValues.errorTrigger[2] && sizeVal(cidade)}
              />
            </FormGroup>
          </div>
          <div className="col-3">
            <FormGroup className="required">
              <label htmlFor="UF">UF</label>
              <TextField
                maxlength={2}
                name="UF"
                value={uf}
                placeholder="UF"
                readOnly={isUfAutoFilled} // Defina o campo como readonly se for preenchido automaticamente
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
                onChange={handleChangeUF}
                success={!ufVal(uf)} // Verifica se a UF é válida e tem 2 caracteres
                error={formValues.errorTrigger[2] && ufVal(uf)}
              />
            </FormGroup>
          </div>
        </div>
        <div className="col-12" style={{ padding: '0px' }}>
          <FormGroup className="form-group" style={{ marginTop: '-12px' }}>
            <LabelInfo>
              * O endereço de faturamento deve ser o mesmo cadastrado na Receita
              Federal.
            </LabelInfo>
          </FormGroup>
        </div>
      </div>
    </>
  )
}

export default RegisterStep2
