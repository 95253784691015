import * as S from './styles'
import { FiCheckSquare } from 'react-icons/fi'

const CadastroCard = () => {
  return (
    <S.Page>
      <S.Wrapper>
        <S.IconWrapper>
          <FiCheckSquare className="icon" />
        </S.IconWrapper>
        <S.Tittle>Seu cadastro foi concluído com sucesso</S.Tittle>
        <S.Description></S.Description>
        <S.Footer>
          <S.Button
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_URL_MAIN}`)
            }
          >
            Ok
          </S.Button>
        </S.Footer>
      </S.Wrapper>
    </S.Page>
  )
}

export default CadastroCard
