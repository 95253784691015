import clientes from '../../api/clientes'
import { validDDDs } from '../FormPF/fieldValidator'

export const cnpjRepetido = async (documento, setReceitaFederalEnabled) => {
  const res = await clientes.validarDocumento(documento)
  const body = await res.json()

  setReceitaFederalEnabled(body.validacaoExternaDadosClientePJ)
  localStorage.setItem('preCadastro', 'false')

  if (!!body.preCadastro) {
    localStorage.setItem('preCadastro', 'true')
    return undefined
  }
  if (!!body.existe) {
    return 'CNPJ já cadastrado'
  }

  return undefined
}

export const cnpjRepetidoVal = (value) => {
  return value
}

export const validatePassword = (password, fieldName, confirmPassword) => {
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const specialCharRegex = /[(!@#$%^*&)]/
  const numberRegex = /\d/

  const passwordValidation = {
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
    length: false,
    matchConfirmation: false
  }

  if (fieldName === 'senha' && password !== undefined) {
    passwordValidation.uppercase = uppercaseRegex.test(password)
    passwordValidation.lowercase = lowercaseRegex.test(password)
    passwordValidation.specialChar = specialCharRegex.test(password)
    passwordValidation.number = numberRegex.test(password)
    passwordValidation.length = password.length >= 8
    passwordValidation.matchConfirmation = password === confirmPassword
  }

  if (fieldName === 'confirmaSenha') {
    passwordValidation.matchConfirmation = password === confirmPassword
  }
  return passwordValidation
}

export function validateReceitaError(error, field, message) {
  // console.log({ field, message })
  // if (field === 'Inscrição Estadual' && error) return `${message}`

  // if (error) return `${field} não condiz com Receita Federal`
  if (error) return `${message}`
}
function validarCNPJ(cnpj) {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]+/g, '')

  // Verifica se o CNPJ tem 14 dígitos
  if (!cnpj || cnpj.length !== 14) return false

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1{13}$/.test(cnpj)) return false

  // Função auxiliar para cálculo dos dígitos verificadores
  const calcularDigito = (cnpj, tamanho) => {
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += cnpj.charAt(tamanho - i) * pos--
      if (pos < 2) pos = 9
    }
    return soma % 11 < 2 ? 0 : 11 - (soma % 11)
  }

  // Valida o primeiro dígito verificador
  const tamanho = 12
  const numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  const digito1 = calcularDigito(numeros, tamanho)
  if (digito1 !== parseInt(digitos.charAt(0), 10)) return false

  // Valida o segundo dígito verificador
  const digito2 = calcularDigito(cnpj.substring(0, tamanho + 1), tamanho + 1)
  if (digito2 !== parseInt(digitos.charAt(1), 10)) return false

  return true
}

export const cnpjVal = (value = '', extraError = undefined) => {
  if (value?.length < 1) return 'Preencher campo obrigatório'
  if (value?.length < 18) return 'CNPJ deve ser composto de 14 dígitos'
  if (!validarCNPJ(value)) return 'CNPJ inválido'
  if (extraError !== undefined) return extraError
  return undefined
}

export const contatoVal = (value = '') =>
  value?.length < 1 ? 'Preencher campo obrigatório' : undefined

export const razaoVal = (value = '') => {
  if (value?.length < 1) return 'Preencher campo obrigatório'

  return value?.length < 3 ? 'Mínimo de 3 caracteres' : undefined
}

export const nomeFanVal = (value = '') => {
  if (value?.length < 1) return 'Preencher campo obrigatório'

  return value?.length < 3 ? 'Mínimo de 3 caracteres' : undefined
}
export const cellVal = (value) => {
  if (!value || value?.length < 1) return 'Preencher campo obrigatório'

  const sanitizedValue = value.replace(/[^\d]/g, '')

  // Regex para verificar se o número é composto por todos os dígitos iguais
  const repeatedDigitRegex = /^(.)\1{10}$/
  if (repeatedDigitRegex.test(sanitizedValue)) {
    return 'Número de celular inválido'
  }

  // Verifica se o comprimento do número é válido
  if (sanitizedValue.length < 11) {
    return 'Número de celular incompleto'
  }

  // Verificação do DDD válido

  const ddd = parseInt(sanitizedValue.substring(0, 2), 10)
  if (!validDDDs.includes(ddd)) {
    return 'DDD inválido'
  }

  return undefined
}

export const comercialPhoneVal = (value) => {
  if (!value || value?.length < 1) return 'Preencher campo obrigatório'
  const sanitizedValue = value.replace(/[^\d]/g, '')

  // Verifica se o comprimento do número é válido (deve ser 10 dígitos)
  if (sanitizedValue.length < 10) {
    return 'Número de telefone incompleto'
  }

  // Regex para verificar se o número é composto por todos os dígitos iguais
  const repeatedDigitRegex = /^(.)\1{9}$/
  if (repeatedDigitRegex.test(sanitizedValue)) {
    return 'Número de telefone inválido'
  }

  const ddd = parseInt(sanitizedValue.substring(0, 2), 10)
  if (!validDDDs.includes(ddd)) {
    return 'DDD inválido'
  }

  return undefined
}

export const inscEstVal = (value = '', valid, uf) => {
  if (value?.length < 1) return 'Preencher campo obrigatório'
  if (value === 'ISENTO') return undefined

  if (valid === false) return `Inválida para estado de ${uf}`

  return value?.length < 8
    ? 'Inscrição estadual possui no mínimo 8 digitos'
    : undefined
}
export const validateInscEstadual = async (inscricaoEstadual, cepUF) => {
  const data = await clientes
    .getInscricaoEstadual(inscricaoEstadual, cepUF)
    .then((res) => res.text())

  if (data.length === 0 && inscricaoEstadual?.length >= 8) {
    return { valid: true, message: '' }
  } else {
    return { valid: false, message: data }
  }
}

export const inscMunVal = (value = '') =>
  value?.length < 1 ? 'Preencher Inscrição Municipal' : undefined

export const openVal = (value = '') => {
  if (value?.length < 1) return 'Preencher campo obrigatório'
  const now = new Date()
  const nowYear = now.getFullYear()
  const nowMonth = now.getMonth() + 1
  const nowDay = now.getDate()
  const dates = value?.split('/') || []
  const day = +dates[0]
  const month = +dates[1]
  const year = +dates[2]

  if (
    year > nowYear ||
    (year === nowYear && nowMonth < month) ||
    (year === nowYear && month === nowMonth && day > nowDay)
  )
    return 'Favor preencher com data válida'

  if ((value?.length || 0) < 10) return 'Data de abertura obrigatória'
  if (day < 1 || day > 31) return 'O dia de abertura deve ser entre 01 e 31'
  if (month < 1 || month > 12) return 'O mês de abertura deve ser entre 01 e 12'
  if (year < 1700) return 'Favor preencher com ano válido'

  if (
    month === 2 &&
    ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)
  ) {
    if (day > 29) return 'Favor preencher data válida'
  } else if (month === 2) {
    if (day > 28) return 'Favor preencher data válida'
  } else if ([4, 6, 9, 11].includes(month) && day > 30) {
    return 'Favor preencher data válida'
  } else if (day > 31) {
    return 'Favor preencher data válida'
  }

  return undefined
}

export const selectVal = (value = -1) => {
  if (value === -1 || value === '-1') return 'Selecionar campo obrigatório'
  return undefined
}

export const admVal = (value = -1, value2 = -1) => {
  if (+value === -1) return 'Selecionar campo obrigatório'
  // if (+value > +value2)
  //   return 'Numero de funcionarios ADM maior que o número total de funcionários'
  return undefined
}

export const strAdmVal = (value = -1, value2 = -1) => {
  if (+value === -1) return 'Selecionar campo obrigatório'
  // if (value > value2)
  //   return 'Numero de funcionarios ADM maior que o número total de funcionários'
  return ''
}
