//Keys
export const Types = {
  SETDADOS: '@setDados'
}

//Reducer
const INITIAL_STATE = {
  dados: {
    email: '',
    nome: '',
    facebookid: ''
  }
}

export default function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case Types.SETDADOS:
      return { ...state, dados: payload }
    default:
      return state
  }
}

//Actions
export const setDados = (payload) => {
  return { type: Types.SETDADOS, payload: payload }
}
