import { css } from 'styled-components'

export const Scroll = css`
  * {
    &::-webkit-scrollbar {
      border-radius: 5px;
      background: #eeeeee;
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #afaeae;
    }
  }
`
