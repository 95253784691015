import { useState } from 'react'

import * as S from './styles'



const Option = ({ value, label }) => (
  <S.Option value={value}>{label}</S.Option>
)

const Select = ({
  label,
  labelFor = '',
  initialValue = '',
  onInput,
  required = false,
  disabled = false,
  success = false,
  error,
  multiple = false,
  options = [],
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e) => {
    const newValue = e.currentTarget.value
    setValue(newValue)

    !!onInput && onInput(newValue)
  }

  return (
    <S.Wrapper
      disabled={disabled}
      error={!!error}
      success={success}
      required={required}
    >
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      <S.SelectWrapper>
        <S.Select
          disabled={disabled}
          onChange={onChange}
          multiple={multiple}
          value={value}
          name={labelFor}
          id={labelFor}
          {...props}
        >
          {options.map(({ label, value }) => (
            <Option key={value} label={label} value={value} />
          ))}
        </S.Select>
      </S.SelectWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default Select
