import styled, { css } from 'styled-components'
import IconInputError from '../../assets/icons/icon-input-error.svg'
import IconInputSuccess from '../../assets/icons/icon-input-success.svg'

export const InputWrapper = styled.div``

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid #626161;
    border-radius: 6px;
    padding: 15px 15px;
    width: 100%;
    transition: all 400ms;

    @media (max-width: ${theme.media.ipad}) {
      padding-right: 32px;
    }

    &::-ms-reveal {
      display: none;
    }

    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      color: #afaeae;
    }

    &:focus {
      border-color: ${theme.colors.primary};
    }
  `}
`

export const Label = styled.label`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #616161;
  display: block;
  letter-spacing: -1px;
  margin-bottom: 5px;
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-style: italic;
    margin-top: 5px;
    display: block;
    font-size: 12px;
  `}
`

const wrapperModifiers = {
  disabled: (theme) => css`
    ${Label} {
      cursor: not-allowed;
      color: #4a4a4a;
    }

    ${Input} {
      cursor: not-allowed;
      background: ${theme.colors.grey};
    }
  `,

  error: (theme) => css`
    ${InputWrapper} {
      border-color: ${theme.colors.error};
    }

    ${Input} {
      background: ${theme.colors.backgroundError} url(${IconInputError}) right
        10px center no-repeat !important;
      border-color: ${theme.colors.error};
    }
  `,

  success: (theme) => css`
    ${Input} {
      background: ${theme.colors.backgroundSuccess} url(${IconInputSuccess})
        right 10px center no-repeat !important;
      border-color: ${theme.colors.success};
    }
  `,
  required: (theme) => css`
    ${Label} {
      &:after {
        content: '*';
        color: ${theme.colors.primary};
        display: inline-block;
        margin-left: 4px;
      }
    }
  `
}

export const Wrapper = styled.div`
  ${({ theme, disabled, error, success, required }) => css`
    ${disabled && wrapperModifiers.disabled(theme)};
    ${error && wrapperModifiers.error(theme)}
    ${success && wrapperModifiers.success(theme)}
    ${required && wrapperModifiers.required(theme)}

    margin-bottom: 20px;
    position: relative;
    height: 67px;

    small {
      display: block;
      margin-bottom: 5px;
    }
  `}
`
