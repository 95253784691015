import { Route, Switch } from 'react-router-dom'
import './Global.css'
import AppProvider from './hooks'
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme'
import Home from './pages/Home'
import End from './pages/End'
import Auth from './pages/Auth'
import GlobalStyles from './styles/global'
import TagManager from 'react-gtm-module'
import { useEffect } from 'react'
// import HomeNew from './pages/HomeNew'

function App() {
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-5QKH8R'
    })
  }, []);

  useEffect(()=>{
    const script= document.createElement('script');
    script.type = 'text/javascript';
    //script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/235689785-loader.js' 
    script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/894f245f-4ddd-4381-9b88-dd81cbb560bd-loader.js'
    script.async = true;
    document.body.appendChild(script);
  },[])

  return (
    <AppProvider>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Auth} />
          <Route exact path="/cadastro" component={Home} />
          {/* <Route exact path="/cadastroNovo" component={HomeNew} /> */}
          <Route exact path="/cadastro-sucesso" component={End} />
        </Switch>
        <GlobalStyles></GlobalStyles>
      </ThemeProvider>
    </AppProvider>
  )
}

export default App
