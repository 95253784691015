import styled, { css } from 'styled-components'

import * as HeaderStyles from '../../components/Header/styles'
import { PageTitle } from '../../styles/titles'
import {
  Button,
  ButtonBlock,
  ButtonPrimary
} from '../../components/Button/styles'

export const Wrapper = styled.section`
  ${({ theme }) => css`
    margin: 0px 0px 20px 0px;
    width: 100%;
    height: 100vh;

    ${HeaderStyles.Wrapper} {
      border-bottom: 1px solid #eeeeee;
    }

    .form-input,
    .form-select {
      background-color: #ffffff;
    }

    @media (max-width: ${theme.media.ipad}) {
      display: block;
      padding: 13px 0;
      margin: 0;
      padding-bottom: 130px;

      .header-logotipo {
        img {
          margin-left: 0;
        }
      }
    }
  `}
`

export const RegisterWrapper = styled.div`
  ${({ theme }) => css`
    margin: 100px 0;

    @media (max-width: ${theme.media.ipad}) {
      margin: 0;
      padding-bottom: 130px;
    }
  `}
`

export const PageContent = styled.div`
  margin: 60px 0;
`

export const WrapperSmall = styled.div`
  ${({ theme }) => css`
    margin: auto;
    max-width: 600px;
    position: relative;
    width: 100%;

    @media (max-width: ${theme.media.ipad}) {
      max-width: 90%;
    }
  `}
`

export const RegisterCard = styled.div`
  ${({ theme }) => css`
    background: #ffffff;
    padding: 40px 80px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    box-shadow: 4px 3px 7px 2px rgba(0, 0, 0, 0.05);

    @media (max-width: ${theme.media.ipad}) {
      background: transparent;
      box-shadow: none;
      border: 0;
      padding: 0;
    }
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    ${PageTitle(theme)}
  `}
`

export const RegisterColumns = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: -16px;
`

export const RegisterColumn = styled.div`
  /* width: 50%; */
`

export const AdvanceButton = styled.button`
  ${({ theme, disabled }) => css`
    cursor: pointer;
    ${Button(theme)}
    ${ButtonPrimary(theme)}
    ${ButtonBlock}

    ${disabled &&
    css`
      background: ${theme.colors.grey};
    `}
  `}
`
