import styled from 'styled-components'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px;
`

export const Wrapper = styled.div`
  background: #ffffff;
  padding: 40px 80px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  -webkit-box-shadow: 4px 3px 7px 2px rgb(0 0 0 / 5%);
  box-shadow: 4px 3px 7px 2px rgb(0 0 0 / 5%);
  width: 500px;

  @media screen and (max-width: 1170px) {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    padding: 0;
    width: 90%;
  }
`

export const Tittle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  color: #d22630;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  @media screen and (max-width: 1170px) {
    font-size: 20px;
    text-align: center;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Adiciona flex-direction para garantir que os ícones fiquem centralizados verticalmente */

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 120px;
    height: 120px;
    color: #5cc187;
  }

  svg {
    animation: bounce-in 2s ease forwards;
  }

  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }
    50% {
      opacity: 1;
      transform: scale(1.05);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 720px) {
    .icon {
      width: 80px;
      height: 80px;
    }
  }
`

export const Description = styled.p`
  text-align: center;
  font-size: 15px;
  line-height: 20px;
`

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

export const Button = styled.div`
  background: #d22630;
  color: #ffffff;
  display: block;
  width: 183px;
  border-radius: 5px;
  border: 2px solid transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 15px 30px;
  text-align: center;
  transition: all 400ms;
  cursor: pointer;
  &:hover {
    background: #a71e26;
  }
`
