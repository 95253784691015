import React, { createContext, useContext, useCallback, useState } from 'react'
import { v4 } from 'uuid'

import ToastContainer from '../components/ToastContainer/index'

const ToastContext = createContext({})

const ToastProvider = ({ children }) => {
  const [messages, setMessages] = useState([])

  const addToast = useCallback(({ type, title, description, timer }) => {
    const id = v4()

    const toast = {
      id,
      type,
      title,
      description,
      timer
    }

    // setMessages((state) => [...state, toast])
    setMessages(toast)
  }, [])

  const removeToast = useCallback((id) => {
    // setMessages((state) => state.filter((message) => message.id !== id))
    setMessages(messages)
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  )
}

function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}

export { ToastProvider, useToast }
