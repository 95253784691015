import styled, { css } from 'styled-components'
import IconArrowDown from '../../assets/icons/icon-arrow-down.svg'
import IconInputError from '../../assets/icons/icon-input-error.svg'
import IconInputSuccess from '../../assets/icons/icon-input-success.svg'


export const SelectWrapper = styled.div``



export const Select = styled.select`
  background: url(${IconArrowDown}) no-repeat;
  background-position: right 10px center;
  border: 1px solid #afaeae;
  border-radius: 6px;
  font-family: 'Montserrat', sans-serif;
  padding: 15px 15px;
  color: #afaeae;
  width: 100%;
  transition: all 400ms;
  appearance: none;
`

export const Option = styled.option`
  font-family: 'Montserrat', sans-serif;
  color: #000000;
`

export const Label = styled.label`
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #4a4a4a;
  display: block;
  letter-spacing: -1px;
  margin-bottom: 5px;
`

export const Error = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.error};
    font-style: italic;
    margin-top: 5px;
    display: block;
    font-size: 13px;
  `}
`

const wrapperModifiers = {
  disabled: (theme) => css`
    ${Label} {
      cursor: not-allowed;
      color: #4a4a4a;
    }

    ${Select} {
      cursor: not-allowed;
      background: ${theme.colors.grey};
    }
  `,

  error: (theme) => css`
    ${SelectWrapper} {
      border-color: ${theme.colors.error};
    }

    ${Select} {
      background: ${theme.colors.backgroundError}
        url(${IconInputError}) right 10px center no-repeat !important;
      border-color: ${theme.colors.error};
    }
  `,

  success: (theme) => css`
    ${Select} {
      background: ${theme.colors.backgroundSuccess}
        url(${IconInputSuccess}) right 10px center no-repeat !important;
      border-color: ${theme.colors.success};
    }
  `,
  required: (theme) => css`
    ${Label} {
      &:after {
        content: '*';
        color: ${theme.colors.primary};
        display: inline-block;
        margin-left: 4px;
      }
    }
  `
}

export const Wrapper = styled.div`
  ${({ theme, disabled, error, success, required }) => css`
    ${disabled && wrapperModifiers.disabled(theme)};
    ${error && wrapperModifiers.error(theme)}
    ${success && wrapperModifiers.success(theme)}
    ${required && wrapperModifiers.required(theme)}

    margin-bottom: 20px;
    position: relative;
    height: 83px;

    small {
      display: block;
      margin-bottom: 5px;
    }
  `}
`
