import defaultOptions from './defaultoption'

const clientes = {
  async email(email) {
    return await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}clientes/clientes/validar-email?email=${email}`,
      { method: 'get', headers: { 'Content-Type': 'application/json' } }
    )
  },
  async getLoggedUser(params) {
    return await fetch(
      process.env.REACT_APP_API_ENDPOINT + 'clientes/clientes/pessoas-fisicas',
      params
    )
  },
  async getLoggedCompany(params) {
    return await fetch(
      process.env.REACT_APP_API_ENDPOINT +
        'clientes/clientes-pessoas-juridicas',
      params
    )
  },
  async getInscricaoEstadual(inscricaoEstadual, uf) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/pessoas-juridicas/inscricao-estadual/${inscricaoEstadual}/${uf}`,
      {
        method: 'GET',
        headers: defaultOptions.headers
      }
    )
  },
  async post(params) {
    return await fetch(
      process.env.REACT_APP_API_ENDPOINT + 'clientes/clientes/pessoas-fisicas',
      params
    )
  },
  async cargos(params) {
    return await fetch(
      process.env.REACT_APP_API_ENDPOINT + 'clientes/cargos',
      params
    )
  },
  async departamentos(params) {
    return await fetch(
      process.env.REACT_APP_API_ENDPOINT + 'clientes/departamentos',
      params
    )
  },
  async atividades(params) {
    return await fetch(
      process.env.REACT_APP_API_CLIENTES + 'atividades',
      params
    )
  },
  async funcionarios(params) {
    return await fetch(
      process.env.REACT_APP_API_CLIENTES + 'funcionarios',
      params
    )
  },

  async validarDocumento(documento) {
    return await fetch(
      `${process.env.REACT_APP_API_CLIENTES}clientes/validar-documento?documento=${documento}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
  }
}

export default clientes
