import { useState } from 'react'

import * as S from './styles'

const TextField = ({
  name,
  label,
  labelFor = '',
  initialValue = '',
  maxlength = 50,
  onInput,
  required = false,
  disabled = false,
  success = false,
  error,
  type = 'text',
  pattern,
  reference,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e) => {
    const newValue = e.currentTarget.value
    setValue(newValue)

    !!onInput && onInput(newValue)
  }

  return (
    <S.Wrapper
      disabled={disabled}
      error={!!error}
      success={success}
      required={required}
    >
      {!!label && <S.Label htmlFor={labelFor}>{label}</S.Label>}
      <S.InputWrapper>
        <S.Input
          ref={reference}
          maxLength={maxlength}
          type={type}
          disabled={disabled}
          onChange={onChange}
          value={value}
          name={name}
          pattern={pattern}
          {...props}
        />
      </S.InputWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  )
}

export default TextField
