import styled, { css } from 'styled-components'

import { FormGroup } from '../../../components/Form/styles'
import { Wrapper } from '../../../components/Tooltip/styles'

export const TermsWrapper = styled.div`
  margin-top: 30px;

  ${({ theme }) => css`
    @media (max-width: ${theme.media.ipad}) {
      &.last-element {
        margin-bottom: 180px;
      }
    }

    @media (max-width: ${theme.media.cel}) {
      &.last-element {
        margin-bottom: 180px;
      }
    }
  `}

  ${FormGroup} {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
      margin-left: 10px;
    }

    a {
      font-weight: 600;
    }

    ${Wrapper} {
      top: 10px;
    }
  }
`

export const ErrorList = styled.ul`
  font-size: 14px;
  margin: 50px 0px 10px 10px;

  ${({ theme }) => css`
    @media (max-width: ${theme.media.ipad}) {
      margin-top: -110px;
    }

    @media (max-width: ${theme.media.cel}) {
      margin-top: -150px;
      font-size: 12px;
    }
  `};
`
export const TitleErrorList = styled.h3`
  color: #3d3d3d;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0px;
`

export const Error = styled.li`
  ${({ theme, isValid, isPasswordEmpty }) => css`
    color: ${isPasswordEmpty ? '#616161' : isValid ? '#5CC187' : '#D22630'};
    display: flex;
    font-weight: 400;
    align-items: center;
    margin: 10px 0;

    &::before {
      content: '${isPasswordEmpty ? '\u25cf' : isValid ? '\u2714' : '\u25cf'}';
      font-size: 14px;
      padding-right: 10px;
      height: 16px;
      width: 20px;
      color: ${isPasswordEmpty ? '#D22630' : isValid ? '#5CC187' : '#D22630'};
      display: inline-block;
    }
  `}
`

export const CaptchaContainer = styled.div`
  margin-bottom: 16px;
  margin-left: 10px;
`
