import styled, { css } from 'styled-components'

export const LabelTooltip = styled.label`
  right: initial;
  margin-left: 10px;
  top: -10px;
`

export const TooltipBox = styled.div`
  ${({ theme }) => css`
    display: none;
    position: absolute;
    background: #ffffff;
    border: 1px solid #afaeae;
    padding: 10px 15px;
    font-size: 13px;
    line-height: 15px;
    min-width: 250px;
    max-width: 300px;
    left: 30px;
    top: -15px;
    border-radius: 5px;

    @media (max-width: ${theme.media.ipad}) {
      right: 0;
      left: initial;
    }

    @media (max-width: ${theme.media.cel}) {
      right: 0;
      left: initial;
    }
  `}
`

export const Wrapper = styled.div`
  display: inline-block;
  right: 20px;
  position: absolute;
  z-index: 99;
  top: 10px;
  transition: all 400ms;

  i {
    background: 1px solid red;
    cursor: pointer;
    width: 20px;
    height: 20px;
    bottom: -10px;
    position: absolute;
  }

  &:hover {
    ${TooltipBox} {
      display: block;
    }
  }

  // Label Tooltip
  ${LabelTooltip} & {
    right: initial;
    margin-left: 10px;
    top: 30px;
  }
`

export const WrapperL = styled.div`
  display: inline-block;
  left: 280px;
  position: absolute;
  z-index: 99;
  top: 10px;
  transition: all 400ms;

  i {
    background: 1px solid red;
    cursor: pointer;
    width: 20px;
    height: 20px;
    bottom: -10px;
    position: absolute;
  }

  &:hover {
    ${TooltipBox} {
      display: block;
    }
  }

  // Label Tooltip
  ${LabelTooltip} & {
    right: initial;
    margin-left: 10px;
    top: 30px;
  }
`