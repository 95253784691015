import * as S from './styles'

const Header = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <a href={process.env.REACT_APP_URL_MAIN}>
          <S.Logo
            src={process.env.REACT_APP_URL_LOGO}
            alt="Gimba"
            role="image"
          />
        </a>{' '}
        <S.NavLinks>
          <a href="https://www.gimba.com.br/Boleto/Lista">2ª Via do Boleto</a>
          <a href="https://www.gimba.com.br/">Voltar para o Site</a>
        </S.NavLinks>
      </S.Container>
    </S.Wrapper>
  )
}

export default Header
