import styled, { css } from 'styled-components'
import IconInputSuccess from '../../assets/icons/icon-input-success.svg'
import IconInputError from '../../assets/icons/icon-input-error.svg'

export const FormGroup = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;
    position: relative;

    label {
      font-size: 16px;
      font-weight: 500;
      color: #626161;
      display: block;
      letter-spacing: -1px;
      margin-bottom: 5px;
    }

    small {
      display: block;
      margin-bottom: 5px;
    }

    @media (max-width: ${theme.media.ipad}) {
      &.last-element {
        margin-bottom: 132px;
      }
    }

    @media (max-width: ${theme.media.cel}) {
      &.last-element {
        margin-bottom: 180px;
      }
    }

    &.required {
      label {
        &:after {
          content: '*';
          color: ${theme.colors.primary};
          display: inline-block;
          margin-left: 1px;
        }
      }
    }
  `}
`

export const FormGroupIcon = styled(FormGroup)`
  ${({ theme }) => css`
    position: relative;

    .icon {
      position: absolute;
      right: 35px;
      top: 15px;
      cursor: pointer;
      transition: all 400ms;

      &:hover {
        background-color: ${theme.colors.primary};
      }
    }

    .icon-eye {
      top: 18px;
    }
  `}
`

export const FormGroupPlaceholder = css`
  position: relative;

  .form-input {
    padding-left: 60px;
  }
`

export const FormInput = styled.input`
  ${({ theme }) => css`
    border: 2px solid #616161;
    border-radius: 6px;
    padding: 15px 15px;
    width: 100%;
    transition: all 400ms;

    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      color: #afaeae;
    }

    &:focus {
      border-color: ${theme.colors.primary};
    }

    // Feedbacks.
    &.input-success {
      background: ${theme.colors.backgroundSuccess} url(${IconInputSuccess})
        right 10px center no-repeat !important;
      border-color: ${theme.colors.success};
    }

    &.input-error {
      background: ${theme.colors.backgroundError} url(${IconInputError}) right
        10px center no-repeat !important;
      border-color: ${theme.colors.error};
    }
  `}
`

export const FormTextPlaceholder = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
`
