/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup, FormGroupIcon } from '../../../components/Form/styles'
import { emailConfVal, emailVal, validatePassword } from '../fieldValidator'
import TextField from '../../../components/TextField'
import { useState, useEffect } from 'react'
import * as S from './styles'

const RegisterStep1 = ({
  formValues,
  onChange,
  handleKeyDown,
  handleChangeStep
}) => {
  const { email, emailConfirm, password, passwordConfirm } = formValues
  const [passView, setPassView] = useState(true)
  const [passConfView, setPassConfView] = useState(true)
  const [isBackButtonClicked, setBackbuttonPress] = useState(false)

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  const [isNewPasswordEmpty, setNewPasswordEmpty] = useState(true)
  const [isNewPasswordValid, setNewPasswordValid] = useState(false)

  const [passwordValidation, setPasswordValidation] = useState({
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
    length: false,
    matchConfirmation: false
  })

  useEffect(() => {
    const isPasswordValid =
      passwordValidation.uppercase &&
      passwordValidation.lowercase &&
      passwordValidation.specialChar &&
      passwordValidation.number &&
      passwordValidation.length &&
      !isNewPasswordEmpty
    setNewPasswordValid(isPasswordValid)

    if (password !== '' || passwordConfirm !== '') {
      setPasswordValidation(
        validatePassword(password, 'password', passwordConfirm)
      )
    }

    if (password === '') {
      setNewPasswordEmpty(true)
    }
  }, [password, passwordConfirm, passwordValidation.matchConfirmation])

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'password') {
      setNewPasswordEmpty(value === '')
      setPasswordValidation(
        validatePassword(value, 'password', passwordConfirm)
      )
    }
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isBackButtonClicked) {
      setBackbuttonPress(true)
      handleChangeStep(false)
    } else {
      window.history.pushState(null, null, window.location.pathname)
      setBackbuttonPress(false)
    }
  }

  return (
    <div className="col-group">
      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-6">
          <FormGroup className="required">
            <label htmlFor="email">E-mail</label>
            <TextField
              maxlength={80}
              name="email"
              value={email}
              placeholder="Digite seu e-mail"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              error={emailVal(email)}
              success={!emailVal(email)}
            />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup className="required">
            <label htmlFor="email">Confirme seu e-mail</label>
            <TextField
              maxlength={80}
              name="emailConfirm"
              value={emailConfirm}
              placeholder="Confirme seu e-mail"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
              onChange={(e) =>
                onChange(
                  e.target.value.trimStart().toLowerCase(),
                  'emailConfirm'
                )
              }
              onBlur={(e) => {
                onChange(
                  e.target.value.trimEnd().replace(/  +/g, ' '),
                  'emailConfirm'
                )
              }}
              error={
                formValues.errorTrigger[1] && emailConfVal(email, emailConfirm)
              }
              success={!emailConfVal(email, emailConfirm)}
            />
          </FormGroup>
        </div>
      </div>
      <div className="col-12" style={{ padding: '0px' }}>
        <div className="col-6">
          <FormGroup className="form-group required">
            <label htmlFor="email">Senha</label>
            <FormGroupIcon className="form-group-icon">
              <TextField
                maxlength={80}
                name="password"
                type={passView ? 'password' : 'text'}
                value={password}
                placeholder="Digite sua senha"
                success={isNewPasswordValid}
                error={!isNewPasswordValid && formValues.errorTrigger[1]}
                onChange={(e) => {
                  handleChange(e)
                  onChange(e.target.value.trimStart(), 'password')
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
              />
              <span
                className={!passView ? 'icon icon-eye' : 'icon icon-eye-cut'}
                onClick={() => setPassView(!passView)}
              ></span>
            </FormGroupIcon>
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup className="form-group required last-element">
            <label htmlFor="email">Confirme sua senha</label>
            <FormGroupIcon className="form-group-icon">
              <TextField
                maxlength={80}
                name="passwordConfirm"
                type={passConfView ? 'password' : 'text'}
                value={passwordConfirm}
                placeholder="Confirme sua senha"
                success={passwordValidation.matchConfirmation}
                error={
                  !passwordValidation.matchConfirmation &&
                  formValues.errorTrigger[1]
                }
                onChange={(e) => {
                  handleChange(e)
                  onChange(e.target.value.trimStart(), 'passwordConfirm')
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e)
                }}
              />
              <span
                className={
                  !passConfView ? 'icon icon-eye' : 'icon icon-eye-cut'
                }
                onClick={() => setPassConfView(!passConfView)}
              ></span>
            </FormGroupIcon>
          </FormGroup>
        </div>
      </div>
      <div className="col-12" style={{ padding: '0px' }}>
        <S.ErrorList>
          <S.TitleErrorList>
            Senha deve conter os seguintes parâmetros
          </S.TitleErrorList>
          <S.Error
            isValid={passwordValidation.length}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Ter no mínimo 8 caracteres
          </S.Error>
          <S.Error
            isValid={passwordValidation.uppercase}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Uma letra maiúscula
          </S.Error>
          <S.Error
            isValid={passwordValidation.lowercase}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Uma letra minúscula
          </S.Error>
          <S.Error
            isValid={passwordValidation.number}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Um número
          </S.Error>
          <S.Error
            isValid={passwordValidation.specialChar}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            Um caractere especial {'(!@#$%^*&)'}
          </S.Error>
          <S.Error
            isValid={passwordValidation.matchConfirmation}
            isPasswordEmpty={isNewPasswordEmpty}
          >
            A senha e a confirmação devem ser iguais
          </S.Error>
        </S.ErrorList>
      </div>
    </div>
  )
}

export default RegisterStep1
