import RegisterTemplate from '../../templates/cadastro'
import Header from '../../components/Header'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import {
  getLocalStorageLog,
  getQueryParamsToTracelog
} from '../../utils/traceLogAcessos'
import { useQuery } from '../../hooks/useQuery'

function Cadastro() {
  const location = useLocation()
  const query = useQuery()

  useEffect(() => {
    getLocalStorageLog(location.pathname)
    // getQueryParamsToTracelog(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header onlyLogo></Header>
      <RegisterTemplate />
    </>
  )
}

export default Cadastro
