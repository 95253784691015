import styled from 'styled-components'
import { theme } from './theme'

export const AccordionMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  border-radius: 15px;
`

export const AccordionItens = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 15px;
`
export const AccordionItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 15px;
  background-color: ${theme.colors.white};
  transition: 0.25s background-color;
  cursor: pointer;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    margin: 0;
    transition: 0.5s max-height, 0.5s margin;
  }

  .accordion-content.active {
    max-height: fit-content;
    margin: 30px 0 0 0;
  }
`
export const AccordionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 38px;
    height: 38px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
  }

  svg {
    color: ${theme.colors.white};
    height: 25px;
    width: 25px;
  }

  p {
    font-size: 20px;
  }
`

export const AccordionItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centraliza verticalmente os itens */
  color: ${theme.colors.primary};
  font-size: 20.74px;
  font-weight: 400;

  .success-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.success};
    color: ${theme.colors.white};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 30px;
  }

  .success-indicator::before {
    content: '✔';
    font-size: 30px;
    line-height: 1;
  }
`
