export const TOKEN_KEY = 'authtoken'
export const isAuthenticated = () =>
  typeof window !== 'undefined'
    ? localStorage.getItem(TOKEN_KEY) !== null
    : false
export const getToken = () =>
  typeof window !== 'undefined' ? localStorage.getItem(TOKEN_KEY) : ''
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
}
